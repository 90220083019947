import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsGraph from 'highcharts/modules/networkgraph'
import accessibility from 'highcharts/modules/accessibility'

HighchartsGraph(Highcharts)
accessibility(Highcharts)

interface NetworkGraphProps {
  options: Highcharts.Options
}

const HighChartGraph: React.FC<NetworkGraphProps> = ({ options }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const chartInstance = useRef<Highcharts.Chart | null>(null)

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy() // Уничтожаем старый чарт
      chartInstance.current = null
    }

    if (chartRef.current) {
      chartInstance.current = Highcharts.chart(chartRef.current, options) // Создаем новый чарт
    }
  }, [options])

  return <div ref={chartRef} style={{ width: '100%', height: '500px' }}></div>
}

export default HighChartGraph
