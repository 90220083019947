import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  deleteForecastById,
  fetchForecastById,
  generateForecastData,
  modifyCollectionEntries,
  updateForecastData,
  updateForecastParameters
} from '../utils/fetch'
import {
  ITrendline,
  ICollectionEntry,
  IBasicIndicator,
  IDataMapped,
  IForecastPage,
  ICalculatedIndicator,
  IForecast,
  IMappedDataEntry,
  IFetchedDataEntry
} from '../utils/interfaces'
import PopupModal from '../helperComponents/PopUpModal'
import {
  TabbingSwitch,
  AccessDenied,
  FunctionalButton,
  InitialLoadingScreen,
  EntityHeader
} from '../helperComponents/_components'
import {
  combineFetchedData,
  getAllValuesGroupedByKey
} from '../utils/transformingData'

import CustomContextMenu from '../helperComponents/CustomContextMenu'
import EntityTags from '../helperComponents/EntityTags'
import UploadPage from '../helperComponents/UploadPage'
import ChatbotComponent from '../helperComponents/ChatbotComponent'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import { Icon3Dots } from '../helperComponents/Icons'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'
import DataTableValues from '../helperComponents/DataTableValues'

const Forecast = ({
  forecastId,
  favourites,
  userTags,
  readonly,
  scenarios
}: IForecastPage) => {
  try {
    const fullDateRange = useRef<string[]>([])
    const chartData = useRef<IFetchedDataEntry[]>([])
    const files = useRef<File[] | null>(null)
    const [forecastParameters, setForecastParameters] = React.useState(
      {} as IForecast
    )
    const [chartTitles, setChartTitles] = useState<
      {
        [key: string]: string
      }[]
    >([])
    const fetchedAllIndicators = useRef(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    const assosiatedTrendlines = useRef<ITrendline[]>([])
    const [selectedTrendline, setSelectedTrendline] = useState<string>('')

    const [generatingDataDates, setGeneratingDataDates] = useState<{
      firstDate: string
      lastDate: string
    }>({ firstDate: '', lastDate: '' })
    const [isCurrentForecastFavourite, setIsCurrentForecastFavourite] =
      useState(false)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const [transformedEntry, setTransformedEntry] =
      React.useState<IDataMapped>()

    const [pageLoading, setPageLoading] = React.useState(false)
    const [modalDeleteForecast, setModalDeleteForecast] = React.useState(false)
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }
    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [readOnly, setReadOnly] = useState<boolean>(readonly)
    const [detailsTabMode, setDetailsTabMode] = useState('data')
    const [
      modalIndicatorNameAndDescription,
      setModalIndicatorNameAndDescription
    ] = useState(false)

    // const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
    //   []
    // )
    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })
    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteForecast(false)
      setModalIndicatorNameAndDescription(false)
      setContextMenuDetails(defaultContextMenuSettings)
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()

      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }

      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const checkIfForecastIsFavourite = () => {
      if (!favourites?.forecasts?.length) {
        return false
      }

      return favourites.forecasts.some(
        (item: ICollectionEntry) => item.id === forecastId
      )
    }

    const initialFetch = useCallback(
      async (forecastId: string, noClose?: boolean) => {
        try {
          if (!noClose) {
            resetModals()
            setPageLoading(true)
          }
          const {
            parameters,
            data,
            fullDates,
            allIndicators,
            owner
          }: {
            parameters: IForecast
            data: any
            fullDates: string[]
            owner: { profile_image: string; username: string }
            allIndicators: (
              | IBasicIndicator
              | ICalculatedIndicator
              | ITrendline
              | IForecast
            )[]
          } = await fetchForecastById(forecastId)

          if (!parameters || !data) {
            setPageLoading(false)
            setAccessAllowed(false)
            return
          }

          const combinedData = combineFetchedData({
            fetchedData: data,
            allParams: [parameters],
            fullDates,
            dataMode: 'values'
          })

          setOwnerInfo({
            profileImage: owner.profile_image,
            username: owner.username
          })

          const transformed = getAllValuesGroupedByKey(combinedData)
          setTransformedEntry(transformed)
          fetchedAllIndicators.current = allIndicators
          setForecastParameters(parameters)

          assosiatedTrendlines.current = allIndicators.filter(
            (item: any) =>
              item.type === 'trendline' &&
              item.base_indicator === parameters.base_indicator
          ) as ITrendline[]

          chartData.current = data
          setChartTitles([
            {
              [parameters.base_indicator as string]: parameters.title
            }
          ])
          fullDateRange.current = fullDates

          if (!readOnly) {
            setReadOnly(!parameters.is_own)
          }

          const isFavourite = checkIfForecastIsFavourite()
          setIsCurrentForecastFavourite(isFavourite)

          if (!noClose) {
            setPageLoading(false)
          }
          setAccessAllowed(true)
        } catch (err: any) {
          console.error(err)
          window.switchFunctions.home()
        }
      },
      [forecastId]
    )

    const refreshForecastNoClose = () => {
      if (pageLoading) return
      initialFetch(forecastId, true)
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateForecastParameters({
        fid: forecastId,
        title: newName,
        meaning: newDescription,
        short_description: newShortDescription
      })
      if (res) {
        setModalIndicatorNameAndDescription(false)
        refreshForecastNoClose()
      }

      return res
    }

    // const analyseWithAI = async () => {
    //   const exportName = `${forecastParameters.title}.pdf`

    //   const combineDataForExport = () => {
    //     if (!transformedEntry || !fetchedAllIndicators) return null

    //     const description = 'File description: ' + forecastParameters.meaning
    //     const data = transformedEntry.data.map((item) => {
    //       const titles = fetchedAllIndicators.current.find(
    //         (forecast) => forecast.fid === item.title
    //       )
    //       const title = titles ? titles.title : item.title

    //       return {
    //         title,
    //         data: item.values
    //       }
    //     })
    //     return { description, data }
    //   }

    //   const combinedData = combineDataForExport()
    //   if (combinedData) {
    //     const doc = new jsPDF() // eslint-disable-line
    //     doc.setFontSize(12)

    //     doc.text(combinedData.description, 10, 10)

    //     let y = 20
    //     combinedData.data.forEach((item: any) => {
    //       doc.text(item.title + ': ' + JSON.stringify(item.data), 10, y)
    //       y += 10
    //     })

    //     const blob = doc.output('blob')

    //     const file = new File([blob], exportName, {
    //       type: 'application/pdf'
    //     })
    //     files.current = [file]
    //     setDetailsTabMode('ai')

    //     return true
    //   } else {
    //     return false
    //   }
    // }

    const handleUpdateData = async (data: IMappedDataEntry) => {
      const dates = data.dates.map((date) => {
        const parts = date.split('-')
        return `${parts[0]}-${parts[2]}-01`
      })

      const res = await updateForecastData({
        fid: forecastId,
        data: {
          dates,
          data: data.values
        }
      })

      if (res) {
        refreshForecastNoClose()
      }

      return res
    }

    const handleGenerateData = async () => {
      const res = await generateForecastData({
        forecastId,
        trendlineId: selectedTrendline,
        dates: generatingDataDates
      })

      if (res) {
        refreshForecastNoClose()
      }

      return res
    }

    useEffect(() => {
      if (pageLoading) return
      initialFetch(forecastId)
    }, [forecastId])

    if (pageLoading) return <InitialLoadingScreen />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (
      accessAllowed &&
      !pageLoading &&
      forecastParameters &&
      transformedEntry
    ) {
      return (
        <div className="entity forecast">
          <EntityHeader
            subtitle="Forecast"
            ownerInfo={ownerInfo}
            title={forecastParameters.title}
            description={forecastParameters.short_description}
          />
          <div className="entity-blocks">
            <div className="container w-12">
              <div
                className="entity-parameters justify-content-start"
                onClick={resetContextMenuDetails}
              >
                <button
                  className="no-btn flex no-hover"
                  onClick={handleContextMenuDetails}
                >
                  <Icon3Dots />
                </button>
              </div>
            </div>
            <div className="container w-6 large no-scrollbar">
              <div className="entity-info">
                <div className="entity-parameters">
                  <TabbingSwitch
                    options={[
                      {
                        label: 'Data',
                        active: detailsTabMode === 'data',
                        onClick: () => setDetailsTabMode('data'),
                        exists: !readOnly
                      },
                      {
                        label: 'Details',
                        active: detailsTabMode === 'details',
                        onClick: () => setDetailsTabMode('details')
                      },
                      {
                        label: 'AI',
                        active: detailsTabMode === 'ai',
                        onClick: () => setDetailsTabMode('ai'),
                        exists: !readOnly
                      },
                      {
                        label: 'Tags',
                        active: detailsTabMode === 'tags',
                        onClick: () => setDetailsTabMode('tags'),
                        exists: !readOnly
                      }
                    ]}
                    numberVisible={5}
                    fit
                    className="no-border col-12"
                  />
                </div>
                {detailsTabMode === 'details' && (
                  <textarea
                    className="description default-text"
                    contentEditable={false}
                    readOnly
                    value={
                      forecastParameters.meaning || 'No description provided'
                    }
                  />
                )}
                {detailsTabMode === 'data' && (
                  <div className="col-12 flex flex-wrap">
                    <div className="col-12 flex gap-3 p-3 pb-0 m-0">
                      <input
                        type="date"
                        value={generatingDataDates.firstDate}
                        onChange={(e) =>
                          setGeneratingDataDates({
                            ...generatingDataDates,
                            firstDate: e.target.value
                          })
                        }
                        min={new Date().toISOString().split('T')[0]}
                        max="2027-01-01"
                      />
                      <input
                        type="date"
                        value={generatingDataDates.lastDate}
                        onChange={(e) =>
                          setGeneratingDataDates({
                            ...generatingDataDates,
                            lastDate: e.target.value
                          })
                        }
                        min={generatingDataDates.firstDate}
                        max="2027-01-01"
                      />
                      <select
                        value={selectedTrendline}
                        className="wider"
                        onChange={(e) => setSelectedTrendline(e.target.value)}
                      >
                        <option value="">Select trendline</option>
                        {assosiatedTrendlines.current.map((item) => (
                          <option key={item.fid} value={item.fid}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                      <FunctionalButton
                        className="primary wider"
                        functionToExecute={handleGenerateData}
                        initialButtonState="Generate Data"
                        doesReset
                        disabled={
                          generatingDataDates.firstDate === '' ||
                          generatingDataDates.lastDate === '' ||
                          selectedTrendline === ''
                        }
                      />
                    </div>
                    <div className="col-12">
                      <UploadPage
                        fullFetchedIndicatorsParameters={[forecastParameters]}
                        refreshFunction={refreshForecastNoClose}
                        singleIndicatorMode={{
                          exists: true,
                          indicatorId: forecastId
                        }}
                        mode="table"
                        customPostFunction={handleUpdateData}
                        customData={transformedEntry.data[0]}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: detailsTabMode === 'ai' ? 'block' : 'none'
                  }}
                  className="flex-row col-12"
                >
                  <ChatbotComponent
                    page="entity"
                    instructions="Analyze this file containing script information and provide valuable information as well as in-depth analysis of the content. Analyze the values of forecast. Highlight the features of forecast"
                    assistantOn={true}
                    providedFiles={files.current}
                    clearFiles={() => (files.current = null)}
                  />
                </div>
                {detailsTabMode === 'tags' && (
                  <div className="description flex-row col-12">
                    <div className="entity-info-block col-12 default-text">
                      {!readOnly && (
                        <EntityTags
                          type="forecast"
                          allTags={userTags}
                          entityTags={forecastParameters.tags || []}
                          entityId={forecastId}
                          functionRefresh={refreshForecastNoClose}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="container w-6 large scroll no-scrollbar">
              <div className="entity-chart">
                {/* <span className="px-4 entity-parameters">
                    Trendline Chart
                  </span> */}
                <HighChartsLinearChart
                  pageDetails={{
                    type: 'forecast',
                    title: forecastParameters.title,
                    fid: forecastId,
                    refresh: refreshForecastNoClose
                  }}
                  page="entity"
                  chartOptions={{
                    xAxisTitle: 'Month',
                    yAxisTitle: 'Value',
                    title: 'Boundaries',
                    oneAxis: true,
                    downloadButton: true,
                    reactive: true
                  }}
                  chartData={{
                    fetchedData: chartData.current,
                    filteredKeys: [
                      transformedEntry.data.length > 0
                        ? transformedEntry.data[0].title
                        : '',
                      'Trendline Values'
                    ],
                    fullDates: fullDateRange.current,
                    titles: chartTitles
                  }}
                  componentOptions={{
                    noTrendlines: true,
                    inversed: forecastParameters.inverse,
                    own: !readOnly && !forecastParameters.admin_access,
                    parametersFetched: forecastParameters
                  }}
                />
              </div>
            </div>
            <div className="container w-12 fit">
              <div className="container w-12 scroll-x no-borders">
                <div className="container w-6 fit scroll-x">
                  <DataTableValues
                    data={chartData.current}
                    parameters={fetchedAllIndicators.current}
                  />
                </div>
              </div>
            </div>
          </div>
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorNameAndDescription(true)
                },
                title: 'Edit Details',
                noAccess: readOnly
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'forecast',
                    entityId: forecastId,
                    action: isCurrentForecastFavourite ? 'remove' : 'add',
                    entityName: forecastParameters.title,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentForecastFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: refreshForecastNoClose,
                  doesReset: true
                },
                noAccess: forecastParameters.is_own
              },
              {
                onClick: () => {
                  resetModals()
                  setModalDeleteForecast(true)
                },
                title: 'Delete',
                noAccess: readOnly
              }
            ]}
          />

          {modalDeleteForecast && !readOnly && (
            <PopupModal
              isOpen={modalDeleteForecast}
              onClose={() => setModalDeleteForecast(false)}
              title="Are you sure you want to delete this forecast?"
              size="small"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
            >
              <div className="flex-row center middle margin-1">
                <FunctionalButton
                  className="destructive"
                  functionToExecute={async () =>
                    await deleteForecastById(forecastId)
                  }
                  initialButtonState={'Delete'}
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: () => {
                      const indicator = fetchedAllIndicators.current.find(
                        (item: IBasicIndicator) =>
                          item.fid === forecastParameters.base_indicator
                      )
                      if (indicator) {
                        if (indicator.type === 'calculated') {
                          window.switchFunctions.calculated(indicator.fid)
                        } else {
                          window.switchFunctions.indicator(indicator.fid)
                        }
                      }
                    }
                  }}
                />
              </div>
            </PopupModal>
          )}
          {modalIndicatorNameAndDescription && (
            <PopupModal
              isOpen={modalIndicatorNameAndDescription}
              onClose={resetModals}
              title="Edit Indicator Details"
              size="large"
              handleSubmit={() => undefined}
              inner
            >
              <RenameMajorEntity
                functionToExecute={handleRename}
                title={forecastParameters.title}
                description={forecastParameters.meaning}
                short_description={forecastParameters.short_description}
              />
            </PopupModal>
          )}
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default Forecast
