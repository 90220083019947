import React, { useMemo, useRef } from 'react'
import { IFetchedDataEntry, IRow, IIndicator } from '../utils/interfaces'
import { ScrollTableButtons } from './_components'
import { ChartLine } from '../charts/interfaces'
import {
  transformCellsIntoColouredCells,
  generateTransformedRows
} from '../utils/transformingData'

const DataTableValues = ({
  data,
  parameters,
  vertical = false,
  chartLines = []
}: {
  data: (IFetchedDataEntry | IRow)[]
  parameters: IIndicator[]
  vertical?: boolean
  chartLines?: ChartLine[]
}) => {
  try {
    const keys = useRef(
      data.length > 0
        ? [
            'date',
            ...Object.keys(data[0]).filter(
              (key) => key !== 'id' && key !== 'date'
            )
          ]
        : []
    )

    const transformedData = useMemo(() => {
      if (!chartLines || chartLines.length === 0 || !vertical) {
        return generateTransformedRows(data)
      }

      return transformCellsIntoColouredCells(data, chartLines)
    }, [data, chartLines])

    const handleMoveToEntityPage = (key: string) => {
      const parameter = Array.isArray(parameters)
        ? parameters.find((param) => param.fid === key || param.title === key)
        : undefined
      if (parameter) {
        switch (parameter.type) {
          case 'indicator':
          case 'actuals':
          case 'custom':
          case 'original':
          case 'internal': // ????
            return window.switchFunctions.indicator(parameter.fid)
          case 'calculated':
            return window.switchFunctions.calculated(parameter.fid)
          case 'external':
            return window.switchFunctions.external(parameter.fid)
          case 'forecast':
            return window.switchFunctions.forecast(parameter.fid)
          case 'trendline':
            return window.switchFunctions.trendline(parameter.fid)
          default:
            return null
        }
      }
      return null
    }

    if (vertical) {
      return (
        <>
          <ScrollTableButtons vertical />
          <div className="data-table-values-scroll-container vertical">
            <table className="data-table-values">
              <thead>
                <tr>
                  {keys.current.map((key, index) => (
                    <td
                      key={key + '_' + index + '_key'}
                      className="data-table-values__value pointer"
                      onClick={() => handleMoveToEntityPage(key)}
                    >
                      {Array.isArray(parameters)
                        ? parameters.find((param) => param.fid === key)
                            ?.title || key
                        : key}
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {transformedData.map((row, index) => (
                  <tr key={'row_' + index}>
                    {keys.current.map((key, ind) => (
                      <td
                        key={key + '_' + ind + '_value'}
                        className="data-table-values__value"
                        style={{ backgroundColor: row[key].color }}
                      >
                        {row[key].value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )
    }

    return (
      <>
        <ScrollTableButtons />
        <div className="data-table-values-scroll-container">
          <table className="data-table-values">
            <tbody>
              {keys.current.map((key) => (
                <tr key={key}>
                  <td
                    className="data-table-values__key"
                    title={
                      Array.isArray(parameters)
                        ? parameters.find((param) => param.fid === key)
                            ?.title || key
                        : key
                    }
                    onClick={() => handleMoveToEntityPage(key)}
                  >
                    {Array.isArray(parameters)
                      ? parameters.find((param) => param.fid === key)?.title ||
                        key
                      : key}
                  </td>
                  {data.map((entry, index) => (
                    <td
                      className="data-table-values__value"
                      key={'value_' + entry[key] + '_' + index}
                    >
                      {entry[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  } catch (error) {
    console.error('Error in DataTableValues', error)
    return null
  }
}

export default DataTableValues
