import React from 'react'
import MiniIndicator from './MiniIndicator'
import MiniScenario from './MiniScenario'
import { IMiniEntity } from '../utils/interfaces'
import MiniCalculated from '../helperComponents/MiniCalculated'
import MiniExternal from '../helperComponents/MiniExternal'

const MiniEntity: React.FC<IMiniEntity> = ({ id, type }) => {
  if (!id || !type) return null

  if (type === 'indicator') {
    return <MiniIndicator indicatorId={id} />
  } else if (type === 'scenario') {
    return <MiniScenario fid={id} />
  } else if (type === 'calculated') {
    return <MiniCalculated indicatorId={id} />
  } else if (type === 'external') {
    return <MiniExternal indicatorId={id} />
  } else {
    return null
  }
}
export default MiniEntity
