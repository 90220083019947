import React from 'react'
import PopupModalNew from '../../helperComponents/PopUpModalNew'
import ChatbotComponent from '../../helperComponents/ChatbotComponent'
import { generateRandomId } from '../../utils/functions'

const MainAppChatGPTPopup = () => {
  return (
    <PopupModalNew
      fid={generateRandomId()}
      title="Chat with GPT-4"
      size="largest"
      buttonFragment={{
        props: {
          className: 'inline no-btn default-text no-hover no-border m-2',
          functionToExecute: () => undefined,
          noReturn: true,
          doesReset: true,
          initialButtonState: 'ChatGPT',
          iconPlusMode: true,
          combinedButtonTitle: true
        }
      }}
    >
      <ChatbotComponent />
    </PopupModalNew>
  )
}

export default MainAppChatGPTPopup
