import React, { Fragment, useEffect } from 'react'
import { PopupModalPropsNew } from '../utils/interfaces'
import { FunctionalButton } from './_components'
import { IconBackwards } from './Icons'

const PopupModalNew = ({
  title,
  children,
  buttonFragment,
  size,
  fid,
  handleSubmit,
  saveButtonDisabled,
  saveButtonExists,
  undoButtonExists,
  handleUndo,
  titleButton,
  customMessage,
  className,
  noChanges,
  fullHeight,
  refreshOnComplete,
  inner,
  nested
}: PopupModalPropsNew) => {
  const [open, setOpen] = React.useState(false)
  const [closeConfirmation, setCloseConfirmation] = React.useState(false)

  const buttonOnlick = () => {
    if (!open) {
      // set id to local storage to prevent multiple popups
      localStorage.setItem('openPopupId', fid)

      setOpen(true)
      return true
    }

    if (closeConfirmation || noChanges) {
      localStorage.removeItem('openPopupId')
      setOpen(false)
    } else {
      setCloseConfirmation(true)
    }

    return true
  }

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && open) {
        buttonOnlick()
      }
    }

    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  return (
    <>
      <FunctionalButton
        {...buttonFragment.props}
        functionToExecute={buttonOnlick}
      />
      {open && (
        <div
          className={`popup-panel open ${!inner ? 'inner' : ''}${nested ? ' nested' : ''}${fullHeight ? ' full-height' : ''}`}
        >
          <div className="overlay"></div>
          <div className={`panel-container ${size}`}>
            <div className="flex justify-between z-1 h-[40px]">
              <div className="flex-row gap-5">
                <h4 className={titleButton?.exists ? 'z-1' : 'col-12 z-1'}>
                  {title}
                </h4>
                {titleButton &&
                  titleButton.exists &&
                  !titleButton.notFunctional && (
                    <FunctionalButton
                      className={titleButton.class}
                      functionToExecute={titleButton.onClick}
                      initialButtonState={titleButton.title}
                      doesReset
                    />
                  )}
                {titleButton &&
                  titleButton.exists &&
                  titleButton.notFunctional && (
                    <button
                      className={titleButton.class}
                      onClick={titleButton.onClick}
                    >
                      {titleButton.title as React.ReactNode}
                    </button>
                  )}
              </div>
              <button
                className="icon no-btn z-1 scale-75"
                onClick={buttonOnlick}
              >
                <IconBackwards />
              </button>
            </div>
            <div className={`panel-content z-1 ${className}`}>
              {closeConfirmation && (
                <span className="banner-strip info center">
                  {customMessage ||
                    'Are you sure you want to close without saving?'}
                </span>
              )}
              {children}
            </div>
            <div className="flex center middle z-1 gap-1 submit-buttons">
              {undoButtonExists && handleUndo && (
                <FunctionalButton
                  className="teriary inline wide center"
                  functionToExecute={handleUndo}
                  initialButtonState="Undo"
                  doesReset
                />
              )}
              {saveButtonExists && handleSubmit && (
                <FunctionalButton
                  className="primary inline wide center submit-button"
                  functionToExecute={handleSubmit}
                  disabled={saveButtonDisabled}
                  initialButtonState="Confirm"
                  iconSaveMode
                  doesReset
                  combinedButtonTitle
                  refreshOnComplete={refreshOnComplete}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PopupModalNew
