import React, { useState } from 'react'
import { ChartLine } from '../interfaces'
import {
  FunctionalButton,
  ToggleInfo
} from '../../helperComponents/_components'
import { chartlinesCorrelationsFetcher } from '../../utils/fetch'

export default function CorrelationAnalysis({
  chartLines,
  disabled = true
}: {
  chartLines: ChartLine[]
  disabled?: boolean
}) {
  const [firstLineId, setFirstLineId] = useState('')
  const [secondLineId, setSecondLineId] = useState('')
  const [maxLag, setMaxLag] = useState(12)
  const [periodsToCheck, setPeriodsToCheck] = useState(120)
  const [result, setResult] = useState('')

  const handleCalculate = async () => {
    try {
      const result = await chartlinesCorrelationsFetcher(
        chartLines
          .filter(
            (line) =>
              line.appearance_settings.title === firstLineId ||
              line.appearance_settings.title === secondLineId
          )
          .sort((a, b) =>
            a.appearance_settings.title === firstLineId ? -1 : 1
          ),
        maxLag,
        periodsToCheck
      )

      setResult(result.bestLag)

      return true
    } catch (error) {
      console.error('Error in handleCalculate')

      return false
    }
  }

  return (
    <div
      className="small flex col-12"
      style={{
        position: 'relative'
      }}
    >
      <select
        disabled={disabled}
        className="inline narrowish"
        value={firstLineId}
        onChange={(e) => setFirstLineId(e.target.value)}
      >
        <option disabled value="">
          Select Line
        </option>
        {chartLines.map((line) => (
          <option
            key={line.appearance_settings.title}
            value={line.appearance_settings.title}
          >
            {line.appearance_settings.title}
          </option>
        ))}
      </select>

      <select
        disabled={disabled}
        className="inline narrowish"
        value={secondLineId}
        onChange={(e) => setSecondLineId(e.target.value)}
      >
        <option disabled value="">
          Select Line
        </option>
        {chartLines.map((line) => (
          <option
            key={line.appearance_settings.title}
            value={line.appearance_settings.title}
          >
            {line.appearance_settings.title}
          </option>
        ))}
      </select>

      <input
        disabled={disabled}
        type="number"
        className="inline narrowest"
        value={maxLag}
        onChange={(e) => setMaxLag(+e.target.value)}
      />
      <input
        disabled={disabled}
        type="number"
        className="inline narrowest"
        value={periodsToCheck}
        onChange={(e) => setPeriodsToCheck(+e.target.value)}
      />
      <FunctionalButton
        initialButtonState="Calculate"
        iconCalculate
        disabled={disabled || !firstLineId || !secondLineId || !maxLag}
        functionToExecute={handleCalculate}
        className="ternary icon scale-90"
        doesReset
      />
      {result && <label className="ms-2">Best Lag: {result}</label>}
      <ToggleInfo>
        <p className="py-0 my-1 banner-strip info col-12 text-xs">
          Negative Lag (e.g., -15): Shift Line 1 to the right (or Line 2 to the
          left) by 15 units. Positive Lag (e.g., 15): Shift Line 1 to the left
          (or Line 2 to the right) by 15 units.
        </p>
      </ToggleInfo>
    </div>
  )
}
