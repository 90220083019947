import React, { useState } from 'react'
import { isLoggedIn } from '../utils/functions'
import { LoginFormData } from '../utils/interfaces'
import { FunctionalButton } from '../helperComponents/_components'
import { handleFormSubmitLogin } from '../utils/fetch'
import './LoginPage.css'

const LoginPage = () => {
  if (isLoggedIn()) {
    window.location.href = '/'
  }
  const [formData, setFormData] = useState<LoginFormData>({
    username: '',
    password: ''
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleTestUserLogin = () => {
    // redirect to the /
    setFormData({
      username: 'test',
      password: 'test'
    })
    handleFormSubmitLogin({ formData: { username: 'test', password: 'test' } })
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <h1>Welcome Back</h1>
          <p>Please sign in to your account</p>
        </div>
        <div className="login-form">
          <div className="form-field">
            <label className="form-label">Username</label>
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className="form-input"
              placeholder="Enter your username"
            />
          </div>
          <div className="form-field">
            <label className="form-label">Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="form-input"
              placeholder="Enter your password"
            />
          </div>
          <div className="button-group">
            <FunctionalButton
              functionToExecute={() => handleFormSubmitLogin({ formData })}
              initialButtonState={'Sign In'}
              className="primary col-12"
              doesReset
            />
            <FunctionalButton
              functionToExecute={handleTestUserLogin}
              initialButtonState={'Login as Test User'}
              className="secondary col-12"
              doesReset
            />
          </div>
        </div>

        <p className="register-text">
          Do not have an account? Contact the administrator to register.
        </p>
      </div>
    </div>
  )
}

export default LoginPage
