import React, { useEffect, useRef, useState } from 'react'
import { fetchChosenIndicator } from '../utils/fetch'
import {
  ICalculatedIndicator,
  ITrendline,
  IDataMapped,
  IBasicIndicator,
  IIncludedId,
  IMiniCalculated
} from '../utils/interfaces'
import {
  AccessDenied,
  InitialLoadingScreen,
  TabbingSwitch
} from '../helperComponents/_components'
import { generateFakeChartReference } from '../utils/functions'
import {
  getAllValuesGroupedByKey,
  returnMergedByDate
} from '../utils/transformingData'

import { HighChartsLinearChart } from '../charts/HighChartsReact'
import DataTableValues from '../helperComponents/DataTableValues'

const CalculatedIndicator = ({ indicatorId }: IMiniCalculated) => {
  try {
    const fetchedIndicatorParameters = useRef<ICalculatedIndicator>(
      {} as ICalculatedIndicator
    )
    // const [chartLines, setChartLines] = useState<ChartLine[]>([])

    // eslint-disable-next-line
    const allFetchedIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])

    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const [fetchedIndicatorData, setFetchedIndicatorData] = React.useState(
      {} as any
    )

    const fetchedDataTransformed = useRef<IDataMapped>({} as IDataMapped)
    // eslint-disable-next-line
    const fetchedAllIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])

    const [fullDates, setFullDates] = React.useState<string[]>([])
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [pageLoading, setPageLoading] = React.useState(false)

    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }

    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )

    const [selectedIndicatorTab, setSelectedIndicatorTab] =
      useState('googleChart')

    const [includedIds, setIncludedIds] = useState<IIncludedId[]>([])

    const handleIndicatorTabChange = (option: any) => {
      setSelectedIndicatorTab(option)
    }

    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }
    const resetModals = () => {
      window.collapseSidePanel(true)

      setContextMenuDetails(defaultContextMenuSettings)
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      if (pageLoading) return

      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }

        const response = await fetchChosenIndicator(indicatorId)
        if (!response || response.fetchedIndicatorParameters.length === 0) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }

        const fetchedParameters: ICalculatedIndicator =
          response.fetchedIndicatorParameters[0]
        const { trendlines, forecasts, variations } = response

        let allParams = [
          ...response.fetchedIndicatorParameters,
          ...trendlines,
          ...forecasts.parameters,
          ...variations.parameters
        ]

        allParams = allParams
          .reverse()
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.fid === item.fid)
          )
          .reverse()

        const combinedData = returnMergedByDate(undefined, [
          response.fetchedIndicatorData,
          forecasts?.data || [],
          variations?.data || []
        ])

        const transformed = getAllValuesGroupedByKey(combinedData)
        const idsIncluded =
          fetchedParameters.ids_included &&
          fetchedParameters.ids_included.length > 0
            ? fetchedParameters.ids_included
            : [
                {
                  fid: indicatorId,
                  chart: true,
                  type: 'calculated',
                  data: true
                }
              ]

        try {
          const refs = generateFakeChartReference(
            fetchedParameters,
            response.allIndicators
          )
          fetchedParameters.chart_references = [refs]
        } catch (err) {}

        // setChartLines(response.chartLines)
        setFetchedIndicatorData(combinedData)
        fetchedIndicatorParameters.current = fetchedParameters
        allFetchedIndicators.current = allParams
        setFullDates(response.fullDates)
        fetchedAllIndicators.current = response.allIndicators.map(
          (item: any) => {
            if (item.type !== 'calculated') return item
            return allParams.find((ind: any) => ind.fid === item.fid) || item
          }
        )

        fetchedDataTransformed.current = transformed
        setIncludedIds(idsIncluded)
        setAccessAllowed(true)
        setPageLoading(false)
      } catch (err: any) {
        console.error("Couldn't fetch indicator", err)
        setPageLoading(false)
      }
    }

    const refreshIndicatorNoClose = async (contextMenu?: boolean) => {
      if (pageLoading) return

      await fetchIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          resetContextMenuDetails()
        }
      })
    }

    const load = async () => {
      if (pageLoading) return

      await fetchIndicator(indicatorId)
    }

    useEffect(() => {
      load()
    }, [indicatorId])

    if (pageLoading) return <InitialLoadingScreen />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (accessAllowed && !pageLoading && fetchedIndicatorParameters) {
      return (
        <div className="entity">
          <TabbingSwitch
            options={[
              {
                label: 'Chart',
                active: selectedIndicatorTab === 'googleChart',
                onClick: () => handleIndicatorTabChange('googleChart')
              },
              {
                label: 'Table',
                active: selectedIndicatorTab === 'dataTable',
                onClick: () => handleIndicatorTabChange('dataTable')
              },
              {
                label: 'Details',
                active: selectedIndicatorTab === 'details',
                onClick: () => handleIndicatorTabChange('details')
              }
            ]}
            fit
          />
          <div
            className="entity-chart"
            style={{
              display: selectedIndicatorTab === 'googleChart' ? 'block' : 'none'
            }}
          >
            <HighChartsLinearChart
              pageDetails={{
                type: 'calculated',
                title: fetchedIndicatorParameters.current.title,
                fid: indicatorId,
                refresh: () => refreshIndicatorNoClose()
              }}
              allIndicators={fetchedAllIndicators.current}
              chartOptions={{
                xAxisTitle: 'Month',
                yAxisTitle: 'Value',
                title: fetchedIndicatorParameters.current?.title,
                mediumSize: true
              }}
              page="calculated"
              chartData={{
                fetchedData: fetchedIndicatorData,
                // chartLines,
                fullDates,
                titles: includedIds.map((item: IIncludedId) => {
                  const matchedIndicator = fetchedAllIndicators.current.find(
                    (indicator: any) => indicator.fid === item.fid
                  )

                  return {
                    [item.fid]: matchedIndicator?.title || ''
                  }
                }),
                filteredKeys: [...includedIds]
                  .filter((_) => _.chart)
                  .map((item) => item.fid),
                deviations: allFetchedIndicators.current as ITrendline[]
              }}
              componentOptions={{
                own:
                  fetchedIndicatorParameters &&
                  !fetchedIndicatorParameters.current?.admin_access,
                parametersFetched: fetchedIndicatorParameters.current
                // onlyDeviations: indicatorDataMode === 'deviations'
              }}
            />
          </div>
          <div
            className="entity-data"
            style={{
              display: selectedIndicatorTab === 'dataTable' ? 'block' : 'none'
            }}
          >
            <DataTableValues
              data={fetchedIndicatorData}
              parameters={allFetchedIndicators.current}
            />
          </div>
          <div
            style={{
              display: selectedIndicatorTab === 'details' ? 'block' : 'none'
            }}
          >
            <div className="p-2 flex gap-3">
              <h2 className="m-0">
                {fetchedIndicatorParameters.current.title}
              </h2>
              <button
                onClick={() =>
                  window.switchFunctions.calculated(
                    fetchedIndicatorParameters.current.fid
                  )
                }
                className="no-btn no-hover border wide"
              >
                Visit Indicator
              </button>
            </div>
            <p className="px-2">{fetchedIndicatorParameters.current.meaning}</p>
          </div>
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default CalculatedIndicator
