import React, { useEffect, useRef, useState } from 'react'
import { ILinearChartProps } from '../utils/interfaces'
// import { TabbingSwitch } from '../helperComponents/_components'
import createHighchartsLinearChart from './js/HighchartsJS'
import ChartLinesCollapsiblePanel from './ChartLines/ChartLinesCollapsiblePanel'
import { FunctionalToggle } from '../helperComponents/_components'
import DataTableValues from '../helperComponents/DataTableValues'

const HighChartsLinearChart = ({
  chartData,
  chartOptions,
  componentOptions,
  pageDetails,
  page,
  readOnly = false,
  allIndicators
}: ILinearChartProps) => {
  const chartContainerRef = useRef(null)
  const chartInstanceRef = useRef(null)
  const [view, setView] = useState<'chart' | 'table'>('chart')
  // const [scaleType, setScaleType] = useState('linear')

  useEffect(() => {
    if (chartContainerRef.current) {
      if (!chartInstanceRef.current) {
        // Initial chart creation
        const newchart = createHighchartsLinearChart({
          chartData,
          chartOptions,
          componentOptions,
          page,
          container: chartContainerRef.current,
          scaleType: 'absolute'
        })

        chartInstanceRef.current = newchart as any
      } else {
        // Update the chart with new data or options
        ;(chartInstanceRef.current as any).updateChart()
      }
    }

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (
        chartInstanceRef.current &&
        (chartInstanceRef.current as any).chartInstance
      ) {
        ;(chartInstanceRef.current as any).chartInstance.destroy()
        chartInstanceRef.current = null
      }
    }
  }, [chartData, chartOptions, componentOptions])

  return (
    <div className="chart-container">
      <div className="entity-parameters">
        <div>
          <FunctionalToggle
            state={view === 'table'}
            leftTitle="Chart"
            rightTitle="Table"
            functionToExecute={() =>
              view === 'chart' ? setView('table') : setView('chart')
            }
          />
        </div>
        {chartData.chartLines && (
          <ChartLinesCollapsiblePanel
            allIndicators={allIndicators || []}
            pageDetails={pageDetails || {}}
            chartLines={chartData.chartLines}
            readOnly={readOnly}
            view={view}
          />
        )}
      </div>
      {/* {!allIndicators && (
        <TabbingSwitch
          numberVisible={3}
          className="justify-content-start"
          options={[
            {
              label: 'Absolute',
              active: scaleType === 'linear',
              onClick: () => setScaleType('linear')
            },
            {
              label: 'Relative to First',
              active: scaleType === 'relative',
              onClick: () => setScaleType('relative')
            },
            {
              label: 'Logarithmic',
              active: scaleType === 'logarithmic',
              onClick: () => setScaleType('logarithmic')
            }
          ]}
        />
      )} */}
      <div
        style={{ display: view === 'chart' ? 'block' : 'none' }}
        ref={chartContainerRef}
        id="page-chart"
      ></div>
      <div style={{ display: view === 'table' ? 'block' : 'none' }}>
        <DataTableValues
          data={chartData.fetchedData}
          parameters={allIndicators!}
          vertical
          chartLines={chartData.chartLines}
        />
      </div>
    </div>
  )
}

export { HighChartsLinearChart }
