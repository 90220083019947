import React, { useEffect } from 'react'
import './css/style.scss'
import './css/index.css'
import '@djthoms/pretty-checkbox'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import MainApp from './majorComponents/MainApp'
import LoginPage from './authentication/LoginPage'
import LogoutPage from './authentication/LogoutPage'
import { AccessDenied } from './helperComponents/_components'
import AdminPage from './authentication/AdminPage'
import { checkIsAdmin } from './utils/fetch'

function App() {
  const token = localStorage.getItem('token')
  const location = window.location.pathname
  const isLoggedOut = token === 'undefined' || token === null || token === ''

  if (isLoggedOut && location === '/') {
    window.location.href = '/login'
  }

  const checkAccess = async () => {
    if (location === '/admin-controls') {
      const isAdmin = await checkIsAdmin()
      if (!isAdmin) {
        window.location.href = '/'
      }
    }
  }

  useEffect(() => {
    checkAccess()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<MainApp />} />
        <Route path="/admin-controls" element={<AdminPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="*" element={<AccessDenied />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
