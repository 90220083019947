import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
// import html2canvas from 'html2canvas';
// import RechartsLineChart from '../charts/ReLineChart';
import {
  Icon3Dots // IconExpandMore,
} from '../helperComponents/Icons'
import {
  AccessDenied,
  FunctionalButton,
  InitialLoadingScreen,
  EntityHeader,
  AnalyseWithAI
} from '../helperComponents/_components'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  ICollection,
  ICollectionEntry,
  ICustomContextMenuSettings,
  IDataMapped,
  IIncludedId,
  IMemoEntry,
  IScenarioItem,
  IScenarioPage,
  ITrendline
} from '../utils/interfaces'
import {
  // evaluateZone,
  generateIndicatorsKeysObjects,
  getAllValuesGroupedByKey
} from '../utils/transformingData'
import {
  modifyCollectionEntries,
  deleteScenarioById,
  fetchChosenScenario,
  // savePDFToDB,
  saveScenarioAsNew,
  updateRangeSelected,
  updateScenarioNameAndDescription,
  updateIndicatorDataMode,
  fetchScenarioDetails,
  modifyScenarioDescriptionMemo,
  modifyScenarioUpdatesMemo,
  updateEntityIncluded
  // fetchScenarioDetails
} from '../utils/fetch'
import { Checkbox } from 'pretty-checkbox-react'
import PopupModal from '../helperComponents/PopUpModal'
import RangeSlider2 from '../helperComponents/RangeSlider2'
import { transformFetchedRangedDetailsScenariotoRangeInputs } from '../utils/functions'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
// import CoreScenarioIndicatorsSection from '../helperComponents/CoreScenarioIndicatorsSection'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'
import DataEntityParameters from '../helperComponents/DataEntityParameters'
// import DataTableParameters from '../helperComponents/DataTableParameters'
// import DataTableValues from '../helperComponents/DataTableValues'

const GROUPID = 'u12tfv3utv12'

const ScenarioPage = ({
  resetMenus,
  readonly,
  fid,
  fetchedCollections,
  userTags
}: IScenarioPage) => {
  try {
    const userId = useMemo(() => localStorage.getItem('userId') || '', [])

    const contextMenuDefaultState = useMemo(
      () => ({
        show: false,
        top: 0,
        left: -500
      }),
      []
    )

    const [loading, setLoading] = useState<boolean>(false)
    const files = useRef<File[] | null>(null)
    const [fetchedAllIndicators, setFetchedAllIndicators] = useState(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    const [readOnly, setReadOnly] = useState<boolean>(readonly)
    const [scenarioDataForAI, setScenarioDataForAI] = useState<any>(null)

    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50);
    // const [dataSectionCollapsed, setDataSectionCollapsed] = useState<boolean>(false);
    const initialDataBoundaries = useRef<string[]>([])
    const selectedDataBoundaries = useRef<string[]>([])

    const fullDates = useRef<string[]>([])
    const [dateRangeSliderChanged, setDateRangeSliderChanged] =
      useState<boolean>(false)
    // const [fetchedScenarioComparisons, setFetchedScenarioComparisons] = useState<ILoadedComparison[]>([]);
    const fetchedData = useRef<IDataMapped>({ data: [] })
    const scenarioDetails = useRef<IScenarioItem | null>(null)
    // const [dataTabMode, setDataTabMode] = useState<string>('historic')
    const isCurrentCollectionFavourite = useRef<boolean>(false)
    const fetchedRawScenarioData = useRef<any[]>([])
    const fetchedPDFSnapshotsRef = useRef<any[]>([])
    const [saveAsNewTitle, setSaveAsNewTitle] = useState<string>('')
    const [saveAsNewDescription, setSaveAsNewDescription] = useState<string>('')
    const [saveAsNewPublic, setSaveAsNewPublic] = useState<boolean>(false)
    const [modalSaveAsNew, setModalSaveAsNew] = useState<boolean>(false)
    const [modalScenarioRange, setModalScenarioRange] = useState<boolean>(false)
    const [
      modalScenarioNameAndDescription,
      setModalScenarioNameAndDescription
    ] = useState<boolean>(false)
    const [modalDeleteScenario, setModalDeleteScenario] =
      useState<boolean>(false)
    const [includedIds, setIncludedIds] = useState<IIncludedId[]>([])

    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })

    // const [baselineIndicatorToCompareTo, setBaselineIndicatorToCompareTo] =
    //   useState<string>('')
    // const [
    //   baselineIndicatorToCompareToContent,
    //   setBaselineIndicatorToCompareToContent
    // ] = useState<any>(null)
    // const [selectedDataMappedMode, setSelectedDataMappedMode] = useState<'value' | 'arrow' | 'off'>(window.globalSettings.rises_and_drops_in_sd.state);

    const [contextMenuChart, setContextMenuChart] =
      useState<ICustomContextMenuSettings>(contextMenuDefaultState)
    const [contextMenuDetails, setContextMenuDetails] =
      useState<ICustomContextMenuSettings>(contextMenuDefaultState)
    const [contextMenuData, setContextMenuData] =
      useState<ICustomContextMenuSettings>(contextMenuDefaultState)

    const resetContextMenuDetails = () => {
      contextMenuDetails.show && setContextMenuDetails(contextMenuDefaultState)
    }

    const resetContextMenuData = () => {
      contextMenuData.show && setContextMenuData(contextMenuDefaultState)
    }

    const resetContextMenuChart = () => {
      contextMenuChart.show && setContextMenuChart(contextMenuDefaultState)
    }

    const resetContextMenus = () => {
      resetContextMenuChart()
      resetContextMenuDetails()
      resetContextMenuData()
      resetMenus()
    }

    const resetModals = (all?: boolean) => {
      setModalScenarioNameAndDescription(false)
      // setBaselineIndicatorToCompareTo('')
      // if (all) {
      //   setModalChartExpanded(false);
      // }
      resetContextMenus()
      setModalScenarioRange(false)
      setModalSaveAsNew(false)
      setModalDeleteScenario(false)
    }

    const resetScenarioPage = () => {
      resetModals(true)
      fetchedData.current = {
        data: []
      }
      // setDataMappedCellWidth(50);
      // setDataSectionCollapsed(false);
    }

    const fetchScenario = useCallback(
      async (fid: string, noReload?: boolean) => {
        if (!noReload) {
          resetScenarioPage()
          setLoading(true)
        }
        const response = await fetchScenarioDetails(fid) // TODO: Fix

        try {
          const {
            fetchedScenarioData,
            fetchedAllIndicators,
            fetchedScenarioDetails,
            // fetchedSavedComparisonsData,
            fetchedCollections,
            fetchedPDFSnapshots,
            completeDates,
            allIndicators: allIndictaorsNoExternals
          } = await fetchChosenScenario(fid)

          const allIndicators = [
            ...allIndictaorsNoExternals,
            ...fetchedAllIndicators.filter(
              (indicator: IBasicIndicator) => indicator.type === 'external'
            )
          ]

          if (!fetchedScenarioDetails) {
            setLoading(false)
            return
          }

          const favouritesCollection = fetchedCollections.find(
            (collection: ICollection) =>
              collection.collection_name.toLowerCase() === 'favourites'
          )
          const isCurrentScenarioFavourite =
            favouritesCollection !== undefined &&
            favouritesCollection.scenarios !== undefined &&
            favouritesCollection.scenarios.find(
              (item: ICollectionEntry) => item.id === fid
            ) !== undefined

          const transformed = getAllValuesGroupedByKey(fetchedScenarioData)
          const idsIncluded =
            fetchedScenarioDetails.ids_included &&
            fetchedScenarioDetails.ids_included.length > 0
              ? fetchedScenarioDetails.ids_included
              : []
          const transformedRange =
            transformFetchedRangedDetailsScenariotoRangeInputs(
              fetchedScenarioDetails.range_chosen
            )
          const { allKeys, allChartKeys } = generateIndicatorsKeysObjects({
            indicatorsParameters: fetchedAllIndicators,
            selectedChart: idsIncluded
              .filter((item) => item.chart)
              .map((item) => item.fid)
              .join(', '),
            selectedData: idsIncluded
              .filter((item) => item.data)
              .map((item) => item.fid)
              .join(', '),
            dataMode: fetchedScenarioDetails.data_mode,
            backupVisibleKeys: []
          })
          setIncludedIds(
            allKeys.map((item) => ({
              fid: item.title,
              chart:
                allChartKeys.find((key) => key.title === item.title) !==
                undefined,
              data:
                allChartKeys.find((key) => key.title === item.title) !==
                undefined,
              type: item.type
            }))
          )

          isCurrentCollectionFavourite.current = isCurrentScenarioFavourite
          // setFetchedScenarioComparisons(fetchedSavedComparisonsData);
          fetchedPDFSnapshotsRef.current = fetchedPDFSnapshots
          scenarioDetails.current = fetchedScenarioDetails
          setFetchedAllIndicators(allIndicators)
          fullDates.current = completeDates
          setReadOnly(!fetchedScenarioDetails.is_own)
          fetchedRawScenarioData.current = fetchedScenarioData
          setOwnerInfo({
            profileImage: response.owner.profile_image,
            username: response.owner.username
          })

          initialDataBoundaries.current =
            transformFetchedRangedDetailsScenariotoRangeInputs(
              fetchedScenarioDetails.range_complete
            )
          selectedDataBoundaries.current = transformedRange
          fetchedData.current = transformed

          setSaveAsNewTitle(fetchedScenarioDetails.scenario_name)
          setSaveAsNewDescription(fetchedScenarioDetails.scenario_overview)
          setSaveAsNewPublic(fetchedScenarioDetails.is_public)

          const dataForAI = {
            name: fetchedScenarioDetails.scenario_name,
            description: fetchedScenarioDetails.scenario_overview,
            short_description: fetchedScenarioDetails.short_description,
            data: fetchedRawScenarioData.current.map((fetchedDataItem: any) => {
              const transformedDataItem: { [key: string]: any } = {
                date: fetchedDataItem.date
              }

              for (const key in fetchedDataItem) {
                if (key !== 'date') {
                  const matchedIndicator = allIndicators.find(
                    (indicator: any) => indicator.fid === key
                  )

                  if (matchedIndicator) {
                    transformedDataItem[matchedIndicator.title] =
                      fetchedDataItem[key]
                  }
                }
              }

              return transformedDataItem
            })
          }
          setScenarioDataForAI(dataForAI)

          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      },
      [fid]
    )

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        resetContextMenuDetails()
        return
      }
      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const refreshScenario = async () => {
      if (loading) return
      fetchScenario(fid)
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateScenarioNameAndDescription(
        newName,
        newDescription,
        newShortDescription,
        fid
      )

      if (res) {
        setModalScenarioNameAndDescription(false)
        refreshScenario()
      }

      return res
    }

    const updateScenarioMemo = async (content: IMemoEntry[]) => {
      const res = await modifyScenarioDescriptionMemo({
        fid,
        content
      })

      return res
    }

    const refreshScenarioNoClose = async () => {
      if (loading) return
      fetchScenario(fid, true)
    }

    const applyRangeChange = async (lowerValue: string, upperValue: string) => {
      const result = await updateRangeSelected({
        rangeSelected: `[${lowerValue},${upperValue})`,
        entityId: fid,
        entityType: 'scenario'
      })
      if (result) {
        refreshScenario()
      }
      return result
    }

    const addUpdatesToScenario = async (updates: IMemoEntry[]) => {
      const res = await modifyScenarioUpdatesMemo({
        fid,
        content: updates
      })

      if (res) {
        refreshScenarioNoClose()
      }

      return res
    }

    const handleAddToIdsIncluded = async (id: string, type: string) => {
      const newIds = [
        ...includedIds,
        { fid: id, chart: true, data: true, type }
      ]

      const res = await updateEntityIncluded({
        entity: {
          entityId: fid,
          entityType: 'scenario'
        },
        ids: newIds
      })

      return res
    }

    const handleRemoveFromIdsIncluded = async (id: string) => {
      const newIds = includedIds.map((item) =>
        item.fid === id ? { ...item, chart: false, data: false } : item
      )

      const res = await updateEntityIncluded({
        entity: {
          entityId: fid,
          entityType: 'scenario'
        },
        ids: newIds
      })

      if (res) {
        refreshScenarioNoClose()
      }

      return res
    }

    const handleAddRemoveFromIdsIncuded = async ({
      fid,
      add,
      type
    }: {
      fid: string
      add: boolean
      type: string
    }) => {
      if (add) {
        return handleAddToIdsIncluded(fid, type)
      }
      return handleRemoveFromIdsIncluded(fid)
    }

    useEffect(() => {
      if (!loading) {
        fetchScenario(fid)
      }
    }, [fid])

    if (loading || !scenarioDetails) {
      return <InitialLoadingScreen />
    }

    return (
      <div className="entity">
        <EntityHeader
          subtitle="Scenario"
          ownerInfo={ownerInfo}
          title={scenarioDetails.current?.scenario_name || ''}
          description={scenarioDetails.current?.short_description || ''}
        />
        <div className="entity-blocks">
          <div className="container w-12">
            <div className="entity-parameters justify-content-start">
              {!readOnly && (
                <AnalyseWithAI
                  analyseParameters={scenarioDataForAI}
                  files={files}
                  analysisMode="component"
                  instructions="Analyze the scenario by focusing on multiple indicators over different time periods, identifying trends and significant changes. Explore relationships between the indicators and highlight key moments of decline or fluctuation, comparing with previous data. Explain potential causes by considering factors like market events or system changes. Based on these patterns, assess possible future trends, accounting for indicator interactions. Finally, summarize the analysis and mention other areas where you can assist."
                />
              )}
              <button
                className="inline no-btn default-text no-hover no-border"
                onClick={handleContextMenuDetails}
              >
                <Icon3Dots />
              </button>
            </div>
          </div>

          <div className="container w-6 large scroll no-scrollbar">
            <div className="entity-chart">
              {readOnly && (
                <span className="banner-strip small warning col-12">
                  Read Only
                </span>
              )}
              <HighChartsLinearChart
                pageDetails={{
                  type: 'scenario',
                  title: scenarioDetails.current?.scenario_name || '',
                  fid,
                  refresh: refreshScenarioNoClose
                }}
                page="scenario"
                allIndicators={fetchedAllIndicators}
                handleAddRemoveFromIdsIncuded={handleAddRemoveFromIdsIncuded}
                chartOptions={{
                  xAxisTitle: 'Month',
                  yAxisTitle: 'Value',
                  title: scenarioDetails.current?.scenario_name || '',
                  mediumSize: true
                  // reactive: initialChartKeys !== entityChartKeys
                }}
                readOnly={readOnly}
                chartData={{
                  fetchedData: fetchedRawScenarioData.current,
                  filteredKeys: includedIds
                    .filter((_) => _.chart)
                    .map((item) => item.fid),
                  chartLines: scenarioDetails.current?.chartLines || [],
                  titles: includedIds
                    .filter((_) => _.chart)
                    .map((item: IIncludedId) => {
                      const matchedIndicator = fetchedAllIndicators.find(
                        (indicator: any) => indicator.fid === item.fid
                      )

                      return {
                        [item.fid]: matchedIndicator?.title || ''
                      }
                    }),
                  fullDates: fullDates.current,
                  deviations: includedIds
                    .filter((_) => _.chart)
                    .map((item) =>
                      fetchedAllIndicators.find(
                        (indicator: any) => indicator.fid === item.fid
                      )
                    ) as any
                }}
                componentOptions={{
                  own:
                    !readOnly &&
                    scenarioDetails &&
                    !scenarioDetails.current?.admin_access,
                  parametersFetched: scenarioDetails.current ?? undefined
                }}
              />
            </div>
          </div>
          <div className="container w-6 large no-scrollbar">
            <div
              className="entity-data no-scrollbar "
              onClick={resetContextMenuDetails}
            >
              <DataEntityParameters
                page="scenario"
                updateMemo={updateScenarioMemo}
                readOnly={readOnly}
                refreshFunction={refreshScenario}
                fid={fid}
                scenarioDetails={scenarioDetails.current}
                fetchedDataTransformed={fetchedData.current}
                userTags={userTags || []}
                addUpdatesToScenario={addUpdatesToScenario}
                refreshEntityNoClose={refreshScenarioNoClose}
                files={files.current}
                aiInstructions="Analyze this file containing script information and provide valuable information as well as in-depth analysis of the content. Analyze the values of each indicator and trendline and compare them with each other. Highlight the features of each indicator and trendline"
              />
            </div>
          </div>
        </div>
        {modalSaveAsNew && (
          <PopupModal
            isOpen={modalSaveAsNew}
            onClose={() => setModalSaveAsNew(false)}
            title="Save this Scenario as a copy"
            size="medium"
            handleSubmit={() =>
              saveScenarioAsNew(
                localStorage.getItem('userId') || userId,
                saveAsNewTitle,
                saveAsNewDescription,
                GROUPID,
                fid,
                saveAsNewPublic
              )
            }
            saveButtonExists
            saveButtonDisabled={saveAsNewTitle.length === 0}
            customMessage="Are you sure you want to abort? All changes will be lost."
            noChanges
          >
            <div className="flex-row center middle">
              <label>Create a name</label>
              <input
                type="text"
                value={saveAsNewTitle}
                onChange={(e) => {
                  setSaveAsNewTitle(e.target.value)
                }}
              />
            </div>
            <div className="center margin-1">
              <Checkbox
                color="info-o"
                animation="pulse"
                onChange={() => setSaveAsNewPublic(!saveAsNewPublic)}
                checked={saveAsNewPublic}
              >
                Make public
              </Checkbox>
              {saveAsNewPublic && (
                <div className="banner-strip warning">
                  Are you sure you want to make this scenario accessible to
                  everyone?
                </div>
              )}
            </div>
            <div className="flex-row center middle">
              <label>Add scenario description</label>
              <input
                type="text"
                value={saveAsNewDescription}
                onChange={(e) => {
                  setSaveAsNewDescription(e.target.value)
                }}
                style={{ width: '70%' }}
                placeholder="Optional"
              />
            </div>
          </PopupModal>
        )}
        {modalScenarioRange && (
          <PopupModal
            isOpen={modalScenarioRange}
            onClose={() => setModalScenarioRange(false)}
            title="Scenario Range"
            size="medium"
            handleSubmit={() => undefined}
            saveButtonExists={false}
            customMessage="If you leave this page, the changes will not be saved."
            noChanges={!dateRangeSliderChanged}
          >
            <RangeSlider2
              dataArray={fullDates.current}
              initialDetails={initialDataBoundaries.current}
              selectedDetails={selectedDataBoundaries.current}
              dateSliderMode={true}
              iconMode={'save'}
              customStyles={{
                width: 400,
                padding: '6px 20px 6px 5px'
              }}
              onApply={applyRangeChange}
              handleChange={() =>
                !dateRangeSliderChanged &&
                setDateRangeSliderChanged(!dateRangeSliderChanged)
              }
            />
          </PopupModal>
        )}
        {modalDeleteScenario && (
          <PopupModal
            isOpen={modalDeleteScenario}
            onClose={() => setModalDeleteScenario(false)}
            title="Confirm delete this scenario"
            size="small"
            handleSubmit={() => undefined}
            noChanges
          >
            <div className="pt-4 flex-column gap-3 center middle">
              <FunctionalButton
                className="destructive center middle"
                initialButtonState="Confirm"
                functionToExecute={async () => await deleteScenarioById(fid)}
                refreshOnComplete={{
                  exists: true,
                  refreshFunction: () => window.switchFunctions.deleted(fid)
                }}
              />
            </div>
          </PopupModal>
        )}
        {modalScenarioNameAndDescription && (
          <PopupModal
            isOpen={modalScenarioNameAndDescription}
            onClose={resetModals}
            title="Edit Scenario Details"
            size="large"
            handleSubmit={() => undefined}
          >
            <RenameMajorEntity
              functionToExecute={handleRename}
              title={scenarioDetails.current?.scenario_name || ''}
              description={scenarioDetails.current?.scenario_overview || ''}
              short_description={
                scenarioDetails.current?.short_description || ''
              }
            />
          </PopupModal>
        )}
        {/* {modalChartExpanded && (
            <PopupModal
              isOpen={modalChartExpanded}
              onClose={resetModals}
              title="Chart Expanded"
              size="largest"
              noChanges
            >
              <RechartsLineChart
                chartOptions={{
                  xAxisTitle: 'Month',
                  yAxisTitle: 'Value',
                      title: newName,
                  fullSize: true
                  // reactive: initialChartKeys !== entityChartKeys
                }}
                chartData={{
                  fetchedData: fetchedRawScenarioData,
                  filteredKeys: entityChartKeys.map((item) => item.title),
                  titles: entityChartKeys.map((item: IEntityAllKeys) => ({
                    [item.title]: item.name
                  })),
                  fullDates,
                  allKeys: entityChartKeys.map((item) => item.title),
                  deviations: entityChartKeys.map((item) =>
                    fetchedAllIndicators.find(
                      (indicator: ITrendline) =>
                        indicator.fid === item.title
                    )
                  )
                }}
                componentOptions={{
                  retrieveIndicatorZones,
                  own: readOnly && !scenarioDetails.admin_access,
                  parametersFetched: scenarioDetails
                }}
              />
            </PopupModal>
          )} */}

        <CustomContextMenu
          contextMenuSettings={contextMenuDetails}
          menu={[
            {
              onClick: () => {
                resetModals(true)
                setModalScenarioNameAndDescription(
                  !modalScenarioNameAndDescription
                )
              },
              title: 'Edit Details',
              noAccess: readOnly
            },
            {
              onClick: () => {
                resetModals(true)
                setModalSaveAsNew(!modalSaveAsNew)
              },
              title: 'Save As'
            },
            {
              onClick: async () => {
                resetModals()
                await updateIndicatorDataMode({
                  entityFid: fid,
                  entityType: 'scenario',
                  newMode:
                    scenarioDetails.current?.data_mode === 'values'
                      ? 'deviations'
                      : 'values'
                })
                refreshScenario()
              },
              title:
                scenarioDetails.current?.data_mode === 'values'
                  ? 'Deviations Data Mode'
                  : 'Values Data Mode'
            },
            {
              onClick: () =>
                modifyCollectionEntries({
                  entityType: 'scenario',
                  entityId: fid,
                  action: isCurrentCollectionFavourite.current
                    ? 'remove'
                    : 'add',
                  entityName: scenarioDetails.current?.scenario_name || '',
                  collectionId:
                    fetchedCollections?.find(
                      (item: any) =>
                        item.collection_name.toLowerCase() === 'favourites'
                    )?.id || 0
                }),
              title: isCurrentCollectionFavourite.current
                ? 'Remove from My Library'
                : 'Add to My Library',
              functionalButtonSettings: {
                exists: true,
                refreshFunction: () => refreshScenario(),
                doesReset: true
              },
              noAccess: !readOnly
            },
            {
              onClick: () => {
                resetModals(true)
                setModalScenarioRange(true)
              },
              noAccess: readOnly,
              title: 'Edit Range'
            },
            {
              onClick: () => {
                resetModals(true)
                setModalDeleteScenario(true)
              },
              title: 'Delete',
              noAccess: readOnly
            }
            // {
            //   onClick: () => undefined,
            //   title: 'Dummy Submenu',
            //   submenu: [
            //     {
            //       onClick: () => {
            //         alert('Submenu 1')
            //       },
            //       title: 'Click me'
            //     },
            //     {
            //       onClick: () => {
            //         alert('Submenu 2')
            //       },
            //       title: 'Click me too'
            //     }
            //   ]
            // }
          ]}
        />
        {/*
        <CustomContextMenu
          contextMenuSettings={contextMenuData}
          menu={[
            {
              onClick: () => {
                resetModals(true)
                handleSidePanelIndicatorsOpen()
              },
              title: 'Indicators Panel',
              noAccess: readOnly
            },
            {
              onClick: () => {
                resetModals(true)
                switchDatatabMode('scenario-comparison')
              },
              title: 'Comparison Mode'
            }
          ]}
        /> */}
      </div>
    )
  } catch (error) {
    console.error('Error rendering scenario page:', error)
    return <AccessDenied />
  }
}

export default React.memo(ScenarioPage)
