import React, { useState } from 'react'
import { ChartLine } from '../interfaces'
import {
  FunctionalButton,
  ToggleInfo
} from '../../helperComponents/_components'
import { chartlinesFetcher } from '../../utils/fetch'

export default function MathAnalysis({ chartLine }: { chartLine: ChartLine }) {
  const [result, setResult] = useState('')

  const handleCalculate = async () => {
    try {
      const result = await chartlinesFetcher({
        oneOrMany: 'one',
        method: 'maths',
        props: chartLine
      })

      setResult(result.bestDays)

      return true
    } catch (error) {
      console.error('Error in handleCalculate')

      return false
    }
  }

  return (
    <div
      className="small flex col-12"
      style={{
        position: 'relative'
      }}
    >
      <label className="me-1">Day Trading Analysis</label>
      <FunctionalButton
        initialButtonState="Calculate"
        iconCalculate
        functionToExecute={handleCalculate}
        className="secondary icon scale-90"
        doesReset
      />
      {result && <p className="ms-2">{result}</p>}
      <ToggleInfo>
        <p className="py-0 my-1 banner-strip info col-12 text-xs">
          This analysis will calculate the best day or days for trading based on
          the data in the chart
        </p>
      </ToggleInfo>
    </div>
  )
}
