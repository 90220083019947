import React, { useEffect, useRef, useState } from 'react'
import {
  fetchExternalIndicator,
  getAllTrendlinesForExternalIndicator
} from '../utils/fetch'
import {
  IBasicIndicator,
  ITrendline,
  IEntityAllKeys,
  IDataMapped,
  ICalculatedIndicator,
  IIndicator,
  IMiniExternal
} from '../utils/interfaces'
import {
  AccessDenied,
  InitialLoadingScreen,
  TabbingSwitch
} from '../helperComponents/_components'
import {
  generateIndicatorsKeysObjects,
  getAllValuesGroupedByKey,
  returnMergedByDate
} from '../utils/transformingData'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import {
  evaluateLocalStoragePageMode,
  findMatchPercentage,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'

import DataTableValues from '../helperComponents/DataTableValues'

const ExternalPage = ({ indicatorId }: IMiniExternal) => {
  try {
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const fetchedTrendlines = useRef<ITrendline[]>([])
    const fullFetchedIndicatorsParameters = useRef<ITrendline[]>([])
    const fetchedDataTransformed = useRef({} as IDataMapped)
    const fetchedIndicatorData = useRef<any[]>([])
    const fetchedAllIndicators = useRef(
      [] as (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    )
    // const fetchedChartLines = useRef<ChartLine[]>([])

    const fullDates = useRef<string[]>([])
    const [trendlinesFetched, setTrendlinesFetched] = useState<boolean>(false)
    const entityAllKeys = useRef<IEntityAllKeys[]>([])

    const fetchedIndicatorParameters = useRef<IBasicIndicator | null>(null)
    const selectedDataBoundaries = useRef(['', ''])
    const [selectedIndicatorTab, setSelectedIndicatorTab] =
      useState('googleChart')

    const [pageMode, setPageMode] = useState<
      'basic' | 'trendline' | 'forecast'
    >('basic')
    const [pageLoading, setPageLoading] = React.useState(false)

    const [entityChartKeys, setEntityChartKeys] = useState<IEntityAllKeys[]>([])
    const [newName, setNewName] = useState<string>('')

    const resetModals = () => {
      window.collapseSidePanel(true)
      localStorage.removeItem('openPopupId')
    }

    const handleIndicatorTabChange = (option: any) => {
      setSelectedIndicatorTab(option)
    }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }
        const { dataResponse, parametersResponse, allIndicators } =
          await fetchExternalIndicator(indicatorId) // add chartLines here

        const fetchedParameters = parametersResponse[0]
        const externalData = dataResponse

        const { allKeys, allChartKeys } = generateIndicatorsKeysObjects({
          indicatorsParameters: [fetchedParameters as any],
          selectedChart: [indicatorId].join(', '),
          selectedData: [indicatorId].join(', '),
          dataMode: fetchedParameters.data_mode,
          backupVisibleKeys: [indicatorId]
        })

        fetchedIndicatorParameters.current = fetchedParameters
        entityAllKeys.current = allKeys
        // fetchedChartLines.current = chartLines
        selectedDataBoundaries.current =
          transformFetchedRangedDetailsScenariotoRangeInputs(
            fetchedParameters.range_chosen
          )
        fullDates.current = externalData.map((item: any) => item.date)
        fullFetchedIndicatorsParameters.current = [fetchedParameters as any]
        fetchedDataTransformed.current = getAllValuesGroupedByKey(externalData)
        fetchedIndicatorData.current = externalData
        fetchedAllIndicators.current = [...allIndicators, fetchedParameters]

        setNewName(fetchedParameters.title)

        setEntityChartKeys(allChartKeys)

        if (!noClose) {
          setPageLoading(false)
        }
        setAccessAllowed(true)
      } catch (err: any) {
        console.error(err)
        window.switchFunctions.home()
      }
    }

    const fetchTrendlines = async (force = false) => {
      const keys = Object.keys(fetchedIndicatorData.current[0]).filter(
        (key) => key !== 'date' && key !== 'id' && key !== indicatorId
      )
      const existingTrendlines = entityChartKeys
        .filter((key) => key.type === 'trendline')
        .map((key) => [`${key.title} Value`, `${key.title} Deviation`])
        .flat()
      const match = findMatchPercentage(keys, existingTrendlines)

      if ((match > 50 || trendlinesFetched) && !force) {
        setTrendlinesFetched(true)
        return
      }
      setPageLoading(true)

      const { params, data } =
        await getAllTrendlinesForExternalIndicator(indicatorId)
      fetchedTrendlines.current = params

      const mergedData = returnMergedByDate(
        fetchedIndicatorData.current,
        [data],
        true
      )

      const trendlinesIds: string[] = params.map((item: any) => item.fid)
      const { allKeys, allChartKeys } = generateIndicatorsKeysObjects({
        indicatorsParameters: [
          ...fullFetchedIndicatorsParameters.current,
          ...params
        ],
        selectedChart: [indicatorId, ...trendlinesIds].join(', '),
        selectedData: [indicatorId, ...trendlinesIds].join(', '),
        dataMode: fullFetchedIndicatorsParameters.current[0].data_mode,
        backupVisibleKeys: [indicatorId]
      })

      fetchedDataTransformed.current = getAllValuesGroupedByKey(mergedData)
      fetchedIndicatorData.current = mergedData
      entityAllKeys.current = allKeys

      setEntityChartKeys(allChartKeys)
      setPageLoading(false)
    }

    const refreshIndicatorNoClose = (contextMenu?: boolean) => {
      if (pageLoading) return
      fetchIndicator(indicatorId, true).then(() => {})
    }

    const handleNewIndicator = async (indicatorId: string) => {
      if (pageLoading) return
      const pageMode = evaluateLocalStoragePageMode(indicatorId)
      if (indicatorId) {
        await fetchIndicator(indicatorId)
      }
      if (pageMode === 'trendline' && fetchedIndicatorData.current.length) {
        await fetchTrendlines()
      } else {
        setTrendlinesFetched(false)
      }
      setPageMode(pageMode)
    }

    const handleCurrentPageIndexChange = async (index: number) => {
      const currentPage = window.visitedPages[index - 1]
      if (
        currentPage.pageMode === 'trendline' &&
        fetchedIndicatorData.current.length
      ) {
        await fetchTrendlines()
      }
      if (
        currentPage.fid === indicatorId &&
        currentPage.pageMode !== pageMode
      ) {
        setPageMode((currentPage.pageMode as any) || 'basic')
      }
    }

    useEffect(() => {
      handleNewIndicator(indicatorId)
    }, [indicatorId])

    useEffect(() => {
      handleCurrentPageIndexChange(window.currentPageIndex)
    }, [window.currentPageIndex])

    if (pageLoading) return <InitialLoadingScreen />
    else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (
      accessAllowed &&
      !pageLoading &&
      fetchedIndicatorParameters.current
    ) {
      return (
        <div className="entity">
          <TabbingSwitch
            options={[
              {
                label: 'Chart',
                active: selectedIndicatorTab === 'googleChart',
                onClick: () => handleIndicatorTabChange('googleChart')
              },
              {
                label: 'Table',
                active: selectedIndicatorTab === 'dataTable',
                onClick: () => handleIndicatorTabChange('dataTable')
              },
              {
                label: 'Details',
                active: selectedIndicatorTab === 'details',
                onClick: () => handleIndicatorTabChange('details')
              }
            ]}
            fit
          />
          <div
            className="entity-chart"
            style={{
              display: selectedIndicatorTab === 'googleChart' ? 'block' : 'none'
            }}
          >
            {' '}
            {/* {readOnly && <span className="banner-strip small warning col-12">Read Only</span>} */}
            <HighChartsLinearChart
              pageDetails={{
                type: 'external',
                title: newName,
                fid: indicatorId,
                refresh: refreshIndicatorNoClose
              }}
              readOnly={true}
              page="indicator"
              allIndicators={fetchedAllIndicators.current}
              chartOptions={{
                xAxisTitle: 'Month',
                yAxisTitle: 'Value',
                title: newName,
                reactive: true,
                mediumSize: true
                // reactive: initialChartKeys !== entityChartKeys
              }}
              chartData={{
                // chartLines: fetchedChartLines.current,
                fetchedData: fetchedIndicatorData.current,
                fullDates: fullDates.current,
                titles: entityChartKeys.map((item: IEntityAllKeys) => ({
                  [item.title]: item.name
                })),
                filteredKeys: [indicatorId]
                // deviations: fullFetchedIndicatorsParameters
              }}
              componentOptions={{
                own:
                  fetchedIndicatorParameters.current &&
                  !fetchedIndicatorParameters.current.admin_access,
                parametersFetched: fetchedIndicatorParameters.current
              }}
            />
          </div>
          <div
            className="entity-data"
            style={{
              display: selectedIndicatorTab === 'dataTable' ? 'block' : 'none'
            }}
          >
            <DataTableValues
              data={fetchedIndicatorData.current}
              parameters={[fetchedIndicatorParameters.current] as IIndicator[]}
            />
          </div>
          <div
            style={{
              display: selectedIndicatorTab === 'details' ? 'block' : 'none'
            }}
          >
            <div className="p-2 flex gap-3">
              <h2 className="m-0">
                {fetchedIndicatorParameters.current.title}
              </h2>
              <button
                onClick={() => {
                  if (fetchedIndicatorParameters.current) {
                    window.switchFunctions.external(
                      fetchedIndicatorParameters.current.fid
                    )
                  }
                }}
                className="no-btn no-hover border wide"
              >
                Visit Indicator
              </button>
            </div>
            <p className="px-2">{fetchedIndicatorParameters.current.meaning}</p>
          </div>
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default ExternalPage
