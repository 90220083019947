import {
  CreateSeriesFromDataAndModeType,
  Frequency,
  IChartReferenceEntry,
  IIndicatorDictSetting
} from '../charts/interfaces'
import { CALCULATED_TYPES } from './_constants'
import { createInteractionRecord } from './fetch'
import {
  IBasicIndicator,
  ICalculatedIndicator,
  IEntityAllKeys
} from './interfaces'
import {
  findClosestMidnightString,
  findClosestMidnight
} from './transformingData'

const isEmpty = (cell: string | null | undefined | number) => {
  if (
    cell === null ||
    cell === undefined ||
    cell === '' ||
    cell === 'null' ||
    cell === 'undefined' ||
    cell === ' ' ||
    cell === 'NaN' ||
    cell === 'NaN%' ||
    cell === ', ' ||
    cell === 0 ||
    cell === Infinity ||
    cell === -Infinity
  ) {
    return true
  } else {
    return false
  }
}
function generateRandomId() {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const transformFullDateToMonthAndYear = (date: string | Date) => {
  if (typeof date === 'string' && isEmpty(date)) return date
  const converted = findClosestMidnightString(date)
  const shortYear = converted.slice(2, 4)
  const month = converted.slice(5, 7)
  const day = converted.slice(8, 10)
  return `${day}/${month}/${shortYear}`
}

const transformFullDateToYearMonthDay = (date: string) => {
  if (isEmpty(date)) return date

  const converted = findClosestMidnightString(date)
  const year = converted.slice(0, 4)
  const month = converted.slice(5, 7)
  const day = converted.slice(8, 10)
  return `${year}/${month}/${day}`
}

const capitalise = (str: string | undefined) =>
  !str ? '' : str.charAt(0).toUpperCase() + str.slice(1)

const isLoggedIn = () => {
  const token = localStorage.getItem('token')
  // const userId = localStorage.getItem('userId')
  if (
    token === 'undefined' ||
    token === null ||
    token === '' ||
    !token ||
    token === 'null' ||
    token === undefined ||
    token === 'false'
  ) {
    return false
  }
  return true
}

function findHighestValue(arr: (string | number)[]) {
  if (arr.length === 0) {
    return 0
  }
  const numberArr = arr.map(Number)
  const sortedArr = numberArr.sort((a, b) => a - b)
  return sortedArr[sortedArr.length - 1]
}

const getPreviousPage = (deleted?: boolean) => {
  if (deleted) {
    window.visitedPages = window.visitedPages.slice(0, -1)
  }

  const page = window.visitedPages[window.currentPageIndex - 2]
  if (!page) {
    return null
  }

  return page
}

const getNextPage = () => {
  const page = window.visitedPages[window.currentPageIndex]

  if (!page) {
    return null
  }

  return page
}

const getCurrentPage = () => {
  return window.visitedPages[window.currentPageIndex - 1]
}

const addToVisitedPages = ({
  type,
  fid,
  pageMode
}: {
  type: string
  fid: string | null | number
  pageMode?: string
}) => {
  const currentPage = getCurrentPage()
  if (currentPage && currentPage.fid === fid && currentPage.type === type) {
    if (isEmpty(pageMode)) {
      return
    } else if (pageMode === currentPage.pageMode) {
      return
    }
  }

  const currentIndex = window.currentPageIndex
  const visitedPages = window.visitedPages
  if (visitedPages.length === currentIndex) {
    window.currentPageIndex += 1
    window.visitedPages = [
      ...visitedPages,
      {
        type,
        fid,
        pageMode
      }
    ]
  } else {
    const newVisitedPages = [
      ...visitedPages.slice(0, currentIndex),
      {
        type,
        fid,
        pageMode
      }
    ]
    window.visitedPages = newVisitedPages
    window.currentPageIndex = newVisitedPages.length
  }
}

const updateLocalStoragePageHistory = ({
  type,
  fid
}: {
  type: string
  fid: string | null | number
}) => {
  createInteractionRecord(type, fid)
  addToVisitedPages({
    type,
    fid
  })
}

const transformFetchedRangedDetailsScenariotoRangeInputs = (
  details: string
) => {
  // example [1992-01-01,2023-10-02)
  if (!details) return ['', '']
  const detailsArray = details.split(',')
  const firstDate = detailsArray[0].slice(1)
  const secondDate = detailsArray[1].slice(0, -1)
  return [firstDate, secondDate]
}

const transformFetchedRangedDetailsScenariotoRangeInputsSlash = (
  details: string
) => {
  // example [1992-01-01,2023-10-02)
  if (!details) return ['', '']
  const detailsArray = details.replace(/-/g, '/').split(',')
  const firstDate = detailsArray[0].slice(1)
  const secondDate = detailsArray[1].slice(0, -1)
  return [firstDate, secondDate]
}

const findFrequencyFromTheName = (name: string) => {
  if (!name) return 'monthly'
  if (name.toLowerCase().includes('daily')) {
    return 'daily'
  } else if (name.toLowerCase().includes('weekly')) {
    return 'weekly'
  } else if (name.toLowerCase().includes('monthly')) {
    return 'monthly'
  } else if (name.toLowerCase().includes('quarterly')) {
    return 'quarterly'
  } else if (name.toLowerCase().includes('yearly')) {
    return 'yearly'
  }

  return 'monthly'
}

const filterOutDevFromName = (
  name: string | null | undefined,
  replacement = ''
) => {
  // I am the worst fucking hack in this world LOL BUT
  // The Big Boss want the data tables and charts to display exact same titles
  // and I can't be fucked to change the whole logic
  // just to accomodate that shit

  // ... I agree the logic is bad, but it became a kind of a snowman
  // and I will be remaking the Data table anyway, so
  // hopefully this will become obsolete then

  if (isEmpty(name)) return replacement

  if (name?.trim() === ' Deviation') {
    return 'Deviations'
  }

  return (name as string).replace(' Deviation', replacement)
}

function compareDates(dateStr1: string | Date, dateStr2: string | Date) {
  const date1 = findClosestMidnight(dateStr1)
  const date2 = findClosestMidnight(dateStr2)

  if (date1.getUTCFullYear() !== date2.getUTCFullYear()) {
    return false
  }

  // if the dates are separated by 3 or less days, return true
  const diffTime = Math.abs(date2.getTime() - date1.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (diffDays <= 3) {
    return true
  } else {
    return false
  }
}

const saveDataToCache = (key: string, data: unknown, date?: Date): boolean => {
  if (!date) {
    date = new Date()
  }
  try {
    const object = {
      data,
      date: date.toISOString()
    }

    const stringified = JSON.stringify(object)

    // Save the serialized data to localStorage
    localStorage.setItem(key, stringified)

    return true
  } catch (error) {
    console.error('Failed to save data to cache:', error)
    return false
  }
}

const generateFakeChartReference = (
  params: ICalculatedIndicator,
  allIndicators: IBasicIndicator[]
): IChartReferenceEntry => {
  const indicatorId = params.fid
  const indicatorsDict = {} as any

  const equationIndicators = params.equation.filter(
    (item) => item.value.length > 2
  )

  equationIndicators.forEach((item, index) => {
    indicatorsDict[item.value] = {
      fid: item.value,
      title: allIndicators.find((ind) => ind.fid === item.value)?.title || '',
      mode: item.mode || 'absolute',
      aggregationMethod: item.aggregationMethod || 'first',
      offset: item.offset || 0,
      cagr: item.cagr || 0,
      placeholderValue: 'abcdefghijklmnopqrstuvwxyz'[index]
    }
  })

  delete indicatorsDict[indicatorId]

  const res: IChartReferenceEntry = {
    id: 1000,
    fid: 'fake_fid',
    source_fid: indicatorId,
    source_type: 'calculated',
    chart_contents: [
      {
        settings: {
          tabbingSwitchTitle: 'Line 1',
          seriesDisplayTitle: params.title,
          color: '#000000',
          width: 1,
          fid: indicatorId,
          dashStyle: '',
          yAxis: 'left',
          mode: params.computation_mode as CreateSeriesFromDataAndModeType,
          defaultFrequency: params.frequency as Frequency,
          currentFrequency: params.frequency as Frequency,
          aggregationMethod: 'first',
          equation: params.equation
        },
        chartData: {} as any,
        indicatorsDict,
        addedIndicatorsToLine: [
          {
            fid: indicatorId,
            included: equationIndicators.map((item) => item.value)
          }
        ],
        fid: indicatorId
      }
    ],
    updated_at: new Date().toISOString(),
    created_at: new Date().toISOString()
  }

  return res
}

const generateDefaultIndicatorsDict = (
  index: number,
  settings: Partial<IIndicatorDictSetting>
) => {
  const keys = 'abcdefghijklmnopqrstuvwxyz'

  const defaultObject = {
    fid: settings.fid || '',
    title: settings.title || '',
    mode: settings.mode || 'absolute',
    aggregationMethod: settings.aggregationMethod || 'first',
    offset: settings.offset || 0,
    cagr: settings.cagr || 0,
    placeholderValue: settings.placeholderValue || keys[index]
  }

  return defaultObject
}

const retrieveFromCache = (
  key: string,
  expiry: number = 1000 * 60 * 60 * 24
): {
  data: unknown
  date: string
} => {
  try {
    // Retrieve the serialized data from localStorage
    const item = localStorage.getItem(key)
    if (item === null) {
      return {
        data: null,
        date: ''
      }
    }

    const object = JSON.parse(item)
    const date = new Date(object.date)
    const now = new Date()
    const diffTime = Math.abs(now.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays > expiry) {
      return {
        data: null,
        date: ''
      }
    }

    return object as { data: unknown; date: string }
  } catch (error) {
    console.error('Failed to retrieve data from cache:', error)
    return {
      data: null,
      date: ''
    }
  }
}

const getFilteredKeys = ({
  keys,
  mode,
  backupFids,
  bannedFids
}: {
  keys: IEntityAllKeys[] | undefined
  mode: 'values' | 'deviations' | 'both'
  backupFids?: string[]
  bannedFids?: string[]
}) => {
  let result = []
  const arrayToUse =
    keys !== undefined ? keys.map((item) => item.title) : backupFids || []
  if (mode === 'deviations') {
    result = arrayToUse.map((item) => [item, item + ' Deviation'])
  } else if (mode === 'values') {
    result = arrayToUse.map((item) => [item, item + ' Value'])
  } else {
    result = arrayToUse.map((item) => [
      item,
      item + ' Value',
      item + ' Deviation'
    ])
  }

  return result.flat().filter((item) => !bannedFids?.includes(item))
}

const findMatchPercentage = (arr1: string[], arr2: string[]) => {
  const intersection = arr1.filter((value) => arr2.includes(value))
  return (intersection.length / arr1.length) * 100
}

const printPDF = async (pdfObject: any) => {
  try {
    // create a pdf from the result
    const pdfBlob = await fetch(pdfObject.pdfData).then((res) => res.blob())
    const pdfFile = new File([pdfBlob], pdfObject.filename, {
      type: 'application/pdf'
    })

    // save the pdf to the device
    const pdfUrl = URL.createObjectURL(pdfFile)
    const downloadLink = document.createElement('a')
    downloadLink.href = pdfUrl
    downloadLink.download = pdfObject.filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    return true
  } catch (error) {
    console.error('Error printing PDF:', error)
    return false
  }
}

const generateTitlesForFidsInAString = (
  str: string,
  indicatorsParameters: IBasicIndicator[]
) => {
  const indicatorsAll = indicatorsParameters.map((ind) => {
    return {
      name: ind.fid,
      title: ind.title
    }
  })
  const indicatorsIncludedInString = indicatorsAll.filter((ind) =>
    str.includes(ind.name)
  )

  return indicatorsIncludedInString
}

const getTypeOfCalculated = (parameters: ICalculatedIndicator) => {
  let mode = 'calculated'
  if (parameters.data_mode === 'deviations') {
    mode = 'deviations'
  } else if (parameters.data_mode === 'values') {
    mode = parameters.computation_mode

    if (mode.includes('offset')) {
      mode = 'offset'
    }

    if (mode.includes('---')) {
      mode = mode.split('---')[0]
    }
  }

  return CALCULATED_TYPES[mode as keyof typeof CALCULATED_TYPES]
}

function debounce<T extends any[]>(
  func: (...args: T) => void,
  wait: number
): (...args: T) => void {
  let timeout: any = null

  return function executedFunction(...args: T) {
    const later = () => {
      if (timeout !== null) {
        clearTimeout(timeout)
      }
      func(...args)
    }

    if (timeout !== null) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(later, wait)
  }
}

// eslint-disable-next-line no-unused-vars
const evaluateLocalStoragePageMode = (currentIndicator: string) => {
  const pageMode = localStorage.getItem('indicatorPageMode')
  if (pageMode && pageMode.includes('---')) {
    const parts = pageMode.split('---')
    if (parts[1] === currentIndicator) {
      return parts[0] as 'basic' | 'trendline' | 'forecast'
    }
  }

  return 'basic' as 'basic' | 'trendline' | 'forecast'
}

const generateShades = (hex: string, shades: number, variation: number) => {
  const base = parseInt(hex.slice(1), 16)
  const red = (base >> 16) & 0xff
  const green = (base >> 8) & 0xff
  const blue = base & 0xff

  const threshold = 50 // Minimum value to prevent color from becoming black
  const colors = []

  for (let i = 0; i < shades; i++) {
    const randomiser = Math.random() * 2 - 1.5
    const changeChannel = Math.floor(Math.random() * 3)

    let newRed = red
    let newGreen = green
    let newBlue = blue

    if (changeChannel === 0) {
      newRed = Math.max(threshold, Math.floor(red + i * variation * randomiser))
    } else if (changeChannel === 1) {
      newGreen = Math.max(
        threshold,
        Math.floor(green + i * variation * randomiser)
      )
    } else {
      newBlue = Math.max(
        threshold,
        Math.floor(blue + i * variation * randomiser)
      )
    }

    colors.push(
      `#${((1 << 24) + (newRed << 16) + (newGreen << 8) + newBlue).toString(16).slice(1)}`
    )
  }

  return colors
}

const handleSwitchClick = (mode: 'prev' | 'next' | 'current') => {
  // ts-ignore
  const page =
    mode === 'prev'
      ? getPreviousPage()
      : mode === 'next'
        ? getNextPage()
        : getCurrentPage()

  if (!page) return null

  if (mode === 'prev') {
    window.currentPageIndex -= 1
  } else if (mode === 'next') {
    window.currentPageIndex += 1
  }

  const dontUpdate = mode === 'prev' || mode === 'next'

  switch (page.type) {
    case 'scenario':
      window.switchFunctions.scenario(String(page.fid), dontUpdate)
      break
    case 'indicator':
      window.switchFunctions.indicator(String(page.fid), dontUpdate)
      break
    case 'collection':
      window.switchFunctions.collection(Number(page.fid), dontUpdate)
      break
    case 'memo':
      window.switchFunctions.memo(String(page.fid), dontUpdate)
      break
    case 'trendline':
      window.switchFunctions.trendline(String(page.fid), dontUpdate)
      break
    case 'calculated':
      window.switchFunctions.calculated(String(page.fid), dontUpdate)
      break
    case 'search':
      window.switchFunctions.search(String(page.fid), dontUpdate)
      break
    case 'upload':
      window.switchFunctions.upload()
      break
    case 'forecast':
      window.switchFunctions.forecast(String(page.fid), dontUpdate)
      break
    case 'channel':
      window.switchFunctions.channel(String(page.fid), dontUpdate)
      break
    case 'external':
      window.switchFunctions.external(String(page.fid), dontUpdate)
      break
    case 'indicatorTrendlines':
      window.switchFunctions.indicator(String(page.fid), dontUpdate, true)
      break
    case 'externalTrendlines':
      window.switchFunctions.external(String(page.fid), dontUpdate, true)
      break
    default:
      window.switchFunctions.home()
      break
  }
}

const logout = () => {
  window.location.href = '/logout'
}

export {
  isLoggedIn,
  capitalise,
  updateLocalStoragePageHistory,
  addToVisitedPages,
  getPreviousPage,
  getNextPage,
  findHighestValue,
  isEmpty,
  debounce,
  transformFetchedRangedDetailsScenariotoRangeInputs,
  transformFetchedRangedDetailsScenariotoRangeInputsSlash,
  printPDF,
  transformFullDateToMonthAndYear,
  getCurrentPage,
  compareDates,
  generateRandomId,
  getTypeOfCalculated,
  generateTitlesForFidsInAString,
  getFilteredKeys,
  filterOutDevFromName,
  saveDataToCache,
  generateShades,
  generateDefaultIndicatorsDict,
  retrieveFromCache,
  findMatchPercentage,
  findFrequencyFromTheName,
  generateFakeChartReference,
  evaluateLocalStoragePageMode,
  transformFullDateToYearMonthDay,
  handleSwitchClick,
  logout
}
