import React, { useEffect, useState } from 'react'
import HighChartsGraph from './HighChartsGraph'
import PopupModal from './PopUpModal'
import {
  getDashboardData,
  getDashboardIndicatorZone,
  updateDashboardData
} from '../utils/fetch'
import PopupModalNew from './PopUpModalNew'
import { generateRandomId } from '../utils/functions'
import { FunctionalButton, ToggledCollapsibleBlock } from './_components'
import Highcharts, {
  SeriesNetworkgraphNodesOptions,
  SeriesNetworkgraphOptions
} from 'highcharts'
import { IconClose } from './Icons'
import IndicatorsAndTrendlinesSearch from '../search/IndicatorsAndTrendlinesSearch'
import MiniEntity from '../majorComponents/MiniEntity'
import { IIndicator, IIndicatorType } from '../utils/interfaces'
import { Checkbox } from 'pretty-checkbox-react'

const NetworkChart = ({ allIndicators }: { allIndicators: IIndicator[] }) => {
  const emptyOptions: SeriesNetworkgraphOptions = {
    type: 'networkgraph',
    dataLabels: { enabled: true },
    nodes: [],
    data: []
  }
  const windowOptions = window.globalSettings.dashboard_data ?? {}
  if (!windowOptions || !windowOptions.nodes) {
    windowOptions.nodes = []
  }
  if (!windowOptions || !windowOptions.data) {
    windowOptions.data = []
  }

  const dashboardData = windowOptions ?? emptyOptions
  const [graphNodeSettings, setGraphNodeSettings] = useState<boolean>(false)
  const [selectedNodeId, setSelectedNodeId] = useState<string>('')
  const [newNodeIndicator, setNewNodeIndicator] = useState<string[]>([])
  const [series, setSeries] = useState<SeriesNetworkgraphOptions>(dashboardData)
  const [movingNodes, setMovingNodes] = useState<boolean>(false)

  const staticOptions: Highcharts.Options = {
    chart: {
      type: 'networkgraph',
      animation: movingNodes
    },
    title: {
      text: ''
    },
    credits: {
      enabled: true,
      text: 'Chartit360',
      href: 'https://www.chartit360.com'
    },
    plotOptions: {
      networkgraph: {
        layoutAlgorithm: {
          enableSimulation: false, // Отключаем анимацию
          approximation: 'barnes-hut', // Оптимизация
          gravitationalConstant: 0, // Меньшее отрицательное значение, чтобы узлы не разлетались
          maxIterations: 500, // Увеличиваем число итераций для стабильного размещения
          friction: -0.9, // Уменьшаем трение, чтобы узлы не слишком сильно притягивались
          integration: 'verlet' // Ускоренный алгоритм физики
        },
        link: {
          color: 'black'
        },
        draggable: movingNodes,
        dataLabels: {
          enabled: true,
          linkFormat: '',
          formatter: function () {
            // обрезаем длинные названия только в лейблах
            const maxLength = 15
            const name = this.point.name
            return name.length > maxLength
              ? name.slice(0, maxLength) + '...'
              : name
          },
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#ffffff'
          }
        }
      },
      series: {
        point: {
          events: {
            click: (event: any) => {
              const nodeId = event.point.id
              setSelectedNodeId(nodeId)
              setTimeout(() => setGraphNodeSettings(true), 150)
            }
          }
        }
      }
    }
  }

  const handleConnectNode = async (
    nodeId: string,
    connectedNodes: string[]
  ) => {
    try {
      const newLinks = connectedNodes.map((connectedNodeId) => [
        nodeId,
        connectedNodeId
      ])

      const oldData = (series.data as string[][]) || []

      const newSeries = {
        ...series,
        data: [...oldData, ...newLinks]
      }

      setSeries(newSeries)

      return await updateDashboardData(newSeries)
    } catch (error) {
      console.error('Error updating node connections')
      return false
    }
  }

  const handleDisconnectNode = (nodeId: string, targetNodeId: string) => {
    try {
      const seriesData = series.data as [string, string][]

      // Фильтруем связи, проверяя, что это массив из двух элементов
      const filteredData = seriesData.filter(
        (link) =>
          Array.isArray(link) &&
          link.length === 2 &&
          (link[0] !== nodeId || link[1] !== targetNodeId) &&
          (link[0] !== targetNodeId || link[1] !== nodeId)
      )

      setSeries((prevOptions) => ({
        ...prevOptions,
        data: filteredData
      }))
    } catch (error) {
      console.error('Error while disconnecting node:', error)
    }
  }

  const getConnectedNodes = (
    nodeId: string
  ): {
    id: string
    name: string
  }[] => {
    try {
      const matches = (series.data as [string, string][]).filter(
        (link) =>
          Array.isArray(link) &&
          link.length === 2 &&
          (link[0] === nodeId || link[1] === nodeId)
      )

      const connectedNodes = matches
        .map((link) => (link[0] === nodeId ? link[1] : link[0]))
        .map((connectedNodeId) => {
          const connectedNode = (
            series.nodes as SeriesNetworkgraphNodesOptions[]
          ).find((node) => node.id === connectedNodeId)

          return {
            id: connectedNode?.id || '',
            name: connectedNode?.name || ''
          }
        })

      return connectedNodes
    } catch (error) {
      console.error('Error getting connected nodes:', error)
      return []
    }
  }

  const refreshGraphNoClose = async () => {
    const data = await getDashboardData()
    setSeries(data)

    return !!data
  }

  const addNewIndicatorsToGraph = async () => {
    try {
      const newNodes = await Promise.all(
        newNodeIndicator.map(async (indicatorId) => {
          const nodeName = allIndicators.find(
            (indicator) => indicator.fid === indicatorId
          )?.title

          const { colourIndex } = await getDashboardIndicatorZone(indicatorId)
          const color =
            (
              window.globalSettings.colours_data_mapped as Record<
                number,
                string
              >
            )?.[colourIndex] || '#fffccc'

          return {
            id: indicatorId,
            name: nodeName ?? indicatorId,
            color,
            marker: {
              // symbol: 'url(icon-rectangle.png)',
              symbol: 'square',
              radius: 25,
              height: 30,
              width: 70
            }
          } as SeriesNetworkgraphNodesOptions
        })
      )

      const oldNodes = (series.nodes as SeriesNetworkgraphNodesOptions[]) || []

      const newOptions = {
        ...series,
        nodes: [...oldNodes, ...newNodes]
      }

      setSeries(newOptions)
      setNewNodeIndicator([])

      const updated = await updateDashboardData(newOptions)

      if (!updated) {
        console.error('Failed to update graph')
        return false
      }

      return await refreshGraphNoClose()
    } catch (error) {
      console.error('Failed to fetch indicator zones')
      return false
    }
  }

  const handleDeleteNode = async (nodeId: string | null) => {
    try {
      if (!nodeId) return

      // delete node from nodes and all the references to it in data
      const newNodes = series.nodes?.filter((node) => node.id !== nodeId)
      const newData = (series.data as string[][]).filter(
        (entry) => !entry.includes(nodeId)
      )

      const newSeries = {
        ...series,
        nodes: newNodes,
        data: newData
      }

      setSeries(newSeries)
      return await updateDashboardData(newSeries)
    } catch (error) {
      console.error('Error deleting node:', error)
      return false
    }
  }

  useEffect(() => {
    const updateNodeColors = async () => {
      const updatedNodes = await Promise.all(
        (series.nodes as SeriesNetworkgraphNodesOptions[]).map(async (node) => {
          if (!node.id) return node
          const { colourIndex } = await getDashboardIndicatorZone(node.id)
          const color =
            (
              window.globalSettings.colours_data_mapped as Record<
                number,
                string
              >
            )?.[colourIndex] || '#fffccc'

          return {
            ...node,
            color
          }
        })
      )

      setSeries((prev) => ({
        ...prev,
        nodes: updatedNodes
      }))
    }

    updateNodeColors()
  }, [])

  return (
    <>
      <HighChartsGraph
        options={{
          ...staticOptions,
          series: [series]
        }}
      />
      <div className="flex inline">
        {graphNodeSettings && (
          <PopupModal
            isOpen={true}
            onClose={() => setGraphNodeSettings(false)}
            title={
              allIndicators.find(
                (indicator) => indicator.fid === selectedNodeId
              )?.title || ''
            }
            size="large"
            handleSubmit={() => undefined}
            saveButtonExists={false}
            noChanges
          >
            <div className="col-12 flex justify-between">
              <div></div>
              <div className="flex gap-2">
                <FunctionalButton
                  initialButtonState="Delete"
                  doesReset
                  noReturn
                  functionToExecute={() => {
                    handleDeleteNode(selectedNodeId)
                    setGraphNodeSettings(false)
                  }}
                  className="destructive icon"
                  iconDelete
                />
              </div>
            </div>
            <ToggledCollapsibleBlock
              title="Appearance"
              initialState={true}
              className="t-small-children"
            >
              <div className="flex col-12 py-2 gap-2">
                <label>Connect With</label>
                <div className="col-10">
                  <IndicatorsAndTrendlinesSearch
                    indicators={allIndicators.filter(
                      (indicator) =>
                        series.nodes
                          ?.map((node) => node.id)
                          .includes(indicator.fid) &&
                        indicator.fid !== selectedNodeId
                    )}
                    height={200}
                    inline
                    searchExternal={false}
                    includedIndicators={[]}
                    refreshIndicators={() => {}}
                    direction="down"
                    customMessage=""
                    addFunction={(id: string) =>
                      handleConnectNode(selectedNodeId, [id])
                    }
                    removeFunction={(id: string) =>
                      handleDisconnectNode(selectedNodeId, id)
                    }
                    altFunction={async (id: string) => {}}
                  />
                </div>
              </div>
              <label>List of Connected Nodes</label>
              <ul>
                {getConnectedNodes(selectedNodeId).map(
                  (connectedNode, index) => (
                    <li
                      key={`${connectedNode!.id}_${index}`}
                      className="flex gap-2"
                    >
                      <span className="">{index + 1}.</span>
                      <label className="">{connectedNode!.name}</label>
                      <button
                        className="no-btn no-hover icon"
                        onClick={() =>
                          handleDisconnectNode(
                            selectedNodeId,
                            connectedNode!.id
                          )
                        }
                      >
                        <IconClose />
                      </button>
                    </li>
                  )
                )}
              </ul>
            </ToggledCollapsibleBlock>
            <ToggledCollapsibleBlock
              title="Indicator"
              initialState={false}
              className="t-small-children"
            >
              <MiniEntity
                id={selectedNodeId}
                type={
                  (allIndicators.find(
                    (indicator) => indicator.fid === selectedNodeId
                  )?.type === 'actuals'
                    ? 'indicator'
                    : (allIndicators.find(
                        (indicator) => indicator.fid === selectedNodeId
                      )?.type as IIndicatorType)) || 'indicator'
                }
              />
            </ToggledCollapsibleBlock>
          </PopupModal>
        )}

        <PopupModalNew
          title="Settings"
          size="medium"
          fid={generateRandomId()}
          handleSubmit={() => undefined}
          saveButtonExists={false}
          inner
          noChanges
          className="p-0"
          buttonFragment={{
            props: {
              className: 'inline no-btn default-text no-hover no-border',
              functionToExecute: () => undefined,
              noReturn: true,
              doesReset: true,
              initialButtonState: 'Settings',
              combinedButtonTitle: true,
              iconSettings: true
            }
          }}
        >
          <ToggledCollapsibleBlock
            title="Animation"
            initialState={true}
            className="t-small-children"
          >
            <Checkbox
              checked={movingNodes}
              onChange={() => setMovingNodes((prev) => !prev)}
              shape="curve"
              animation="pulse"
              color="info"
              className="mx-2 my-0"
            >
              Turn animation on (Frontend only)
            </Checkbox>
          </ToggledCollapsibleBlock>
        </PopupModalNew>

        <PopupModalNew
          title=""
          size="medium"
          fid={generateRandomId()}
          handleSubmit={() => addNewIndicatorsToGraph()}
          saveButtonExists={true}
          inner
          noChanges
          className=""
          buttonFragment={{
            props: {
              className: 'inline no-btn default-text no-hover no-border',
              functionToExecute: () => undefined,
              noReturn: true,
              doesReset: true,
              initialButtonState: 'New Node',
              combinedButtonTitle: true,
              iconPlusMode: true
            }
          }}
        >
          <div className="col-12 p-2 ps-1">
            <IndicatorsAndTrendlinesSearch
              indicators={allIndicators}
              includedIndicators={
                series.nodes
                  ? series.nodes.map((node) => node.id as string)
                  : []
              }
              refreshIndicators={() => {}}
              direction="down"
              searchExternal={false}
              customMessage="Add Lines to Chart"
              addFunction={(id: string) => {
                setNewNodeIndicator((prev) =>
                  prev.includes(id)
                    ? prev.filter((fid) => fid !== id)
                    : [...prev, id]
                )
                return true
              }}
              removeFunction={undefined}
              altFunction={async (id: string) => {}}
            />
          </div>
        </PopupModalNew>
      </div>
    </>
  )
}

export default NetworkChart
