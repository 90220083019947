import React, { useState } from 'react'
import { FunctionalButton } from './_components'
import { addToWorkspace } from '../utils/fetch'

const EntitiesTable = ({
  entities
}: {
  entities: {
    fid: string | number
    title: string
    type: string
  }[]
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedIds, setSelectedIds] = useState<any>([])

  const filteredEntities = entities.filter((entity) =>
    entity.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const toggleSelection = (fid: string | number) => {
    setSelectedIds((prevSelectedIds: any[]) =>
      prevSelectedIds.includes(fid)
        ? prevSelectedIds.filter((id: any) => id !== fid)
        : [...prevSelectedIds, fid]
    )

    return true
  }

  const getSelectedTitles = () => {
    return entities
      .filter((entity) => selectedIds.includes(entity.fid))
      .map((entity) => entity.title)
      .join(', ')
  }

  const hadnleAddToWorkspace = async () => {
    const res = await addToWorkspace(selectedIds)

    if (res) {
      window.location.href = '/'
    }

    return res
  }

  return (
    <div className="entities-container">
      <h2>Search and add content</h2>
      <p>Search for content and add it to your workspace</p>
      <p>
        {selectedIds.length > 0
          ? 'You have selected the following titles: ' +
            getSelectedTitles() +
            '.'
          : 'You have not selected any titles yet.'}
      </p>
      <div className="flex px-0 py-2 gap-2">
        <FunctionalButton
          functionToExecute={hadnleAddToWorkspace}
          initialButtonState={'Add to workspace'}
          className="primary wider"
          iconSaveMode
          combinedButtonTitle
          doesReset
          disabled={selectedIds.length === 0}
        />
      </div>
      <input
        type="text"
        placeholder="Search by title..."
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <table className="entities-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Title</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredEntities.map((entity, index) => (
            <tr key={index}>
              <td>{entity.type}</td>
              <td>{entity.title}</td>
              <td>
                <FunctionalButton
                  functionToExecute={() => toggleSelection(entity.fid)}
                  initialButtonState={
                    selectedIds.includes(entity.fid) ? 'Remove' : 'Add'
                  }
                  className="primary"
                  doesReset
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default EntitiesTable
