import React, { useState } from 'react'
import { IndividualChartLineProps } from './types'
import {
  FunctionalButton,
  FunctionalCheckbox,
  Spinner
  //   ToggleInfo
} from '../../helperComponents/_components'
import { chartlinesFetcher } from '../../utils/fetch'
import { BoundariesSettings } from '../interfaces'

export default function ColourCoding(props: IndividualChartLineProps) {
  if (!props.chartLine.boundaries_settings) {
    return <Spinner />
  }

  const [boundariesSettings, setBoundariesSettings] =
    useState<BoundariesSettings>(props.chartLine.boundaries_settings)

  const getValueByIndex = (
    index: 1 | 2 | 3 | 4 | 5 | 6,
    useDefault = false
  ) => {
    const value = useDefault
      ? boundariesSettings.boundaries[index].defaultValue
      : boundariesSettings.boundaries[index].userValue ||
        boundariesSettings.boundaries[index].defaultValue

    return value < 1 ? value.toFixed(5) : value.toFixed(3)
  }

  const setValueByIndex = (index: 1 | 2 | 3 | 4 | 5 | 6, newValue: number) => {
    setBoundariesSettings((prev) => {
      const newBoundaries = { ...prev.boundaries }
      newBoundaries[index].userValue = newValue
      return {
        ...prev,
        boundaries: newBoundaries
      }
    })
  }

  const handleUpdate = async () => {
    try {
      const line = {
        ...props.chartLine,
        boundaries_settings: boundariesSettings
      }

      const updated = await chartlinesFetcher({
        method: 'put',
        oneOrMany: 'one',
        props: line
      })

      if (!updated) {
        return false
      }

      props.updateChartLine(line)
      props.pageDetails.refresh()

      return true
    } catch (error) {
      console.error('Error in handleUpdate')

      return false
    }
  }

  return (
    <>
      <FunctionalCheckbox
        state={boundariesSettings.data_details.inversed}
        functionToExecute={() => {
          setBoundariesSettings((prev) => ({
            ...prev,
            data_details: {
              ...prev.data_details,
              inversed: !prev.data_details.inversed
            }
          }))

          return true
        }}
        title="Inversed"
        color="success-o"
      />
      <div className="flex-column justify-content-start colour-coding-table">
        <div
          className="flex m-1 justify-content-start"
          style={{
            borderColor: window.globalSettings.colours_data_mapped[6]
          }}
        >
          <label className="">
            Data maximum: {boundariesSettings.data_details.max}
          </label>
          {/* <input
          type="number"
          className="narrower inline"
          value={getValueByIndex(6)}
          min={getValueByIndex(5)}
          onChange={(e) => {
            setValueByIndex(6, Number(e.target.value))
          }}
        /> */}
        </div>
        <div
          className="flex m-1 justify-content-start"
          style={{
            borderColor: boundariesSettings.data_details.inversed
              ? window.globalSettings.colours_data_mapped[1]
              : window.globalSettings.colours_data_mapped[6]
          }}
        >
          <label className="">
            Boundary 1 (suggested {getValueByIndex(5, true)}):
          </label>
          <input
            type="number"
            className="narrower inline"
            value={getValueByIndex(5)}
            onChange={(e) => {
              setValueByIndex(5, Number(e.target.value))
            }}
          />
        </div>
        <div
          className="flex m-1 justify-content-start"
          style={{
            borderColor: boundariesSettings.data_details.inversed
              ? window.globalSettings.colours_data_mapped[2]
              : window.globalSettings.colours_data_mapped[5]
          }}
        >
          <label className="">
            Boundary 2 (suggested {getValueByIndex(4, true)}):
          </label>
          <input
            type="number"
            className="narrower inline"
            value={getValueByIndex(4)}
            onChange={(e) => {
              setValueByIndex(4, Number(e.target.value))
            }}
          />
        </div>
        {/* <div className="flex m-1 justify-content-start">
        <label>Data Average {boundariesSettings.data_details.average}</label>
      </div> */}
        <div
          className="flex m-1 justify-content-start"
          style={{
            borderColor: window.globalSettings.colours_data_mapped[3]
          }}
        >
          <label className="">
            Boundary 3 (suggested {getValueByIndex(3, true)}):
          </label>
          <input
            type="number"
            className="narrower inline"
            value={getValueByIndex(3)}
            onChange={(e) => {
              setValueByIndex(3, Number(e.target.value))
            }}
          />
        </div>
        <div
          className="flex m-1 justify-content-start"
          style={{
            borderColor: boundariesSettings.data_details.inversed
              ? window.globalSettings.colours_data_mapped[5]
              : window.globalSettings.colours_data_mapped[2]
          }}
        >
          <label className="">
            Boundary 4 (suggested {getValueByIndex(2, true)}):
          </label>
          <input
            type="number"
            className="narrower inline"
            value={getValueByIndex(2)}
            onChange={(e) => {
              setValueByIndex(2, Number(e.target.value))
            }}
          />
        </div>
        <div
          className="flex m-1 justify-content-start"
          style={{
            borderColor: boundariesSettings.data_details.inversed
              ? window.globalSettings.colours_data_mapped[6]
              : window.globalSettings.colours_data_mapped[1]
          }}
        >
          <label className="">
            Data minimum: {boundariesSettings.data_details.min}
          </label>
          {/* <input
          type="number"
          className="narrower inline"
          value={getValueByIndex(2)}
          min={getValueByIndex(1)}
          max={getValueByIndex(3)}
          onChange={(e) => {
            setValueByIndex(2, Number(e.target.value))
          }}
        /> */}
        </div>
        <FunctionalButton
          initialButtonState="Update"
          iconCalculate
          functionToExecute={handleUpdate}
          className="primary wide p-0 h-[25px] mt-2"
          doesReset
          combinedButtonTitle
        />
      </div>
    </>
  )
}
