import React, { useEffect, useRef, useState } from 'react'
import {
  modifyCollectionEntries,
  deleteIndicatorByName,
  fetchChosenIndicator,
  updateIndicarorDetails,
  updateRangeSelected,
  updateEntityInverse,
  updateIndicatorDataMode,
  createNewForecast
  // updateIndicatorFieldValue
} from '../utils/fetch'
import {
  // ICollection,
  ICollectionEntry,
  ICalculatedIndicator,
  ITrendline,
  IDataMapped,
  ICalculatedIndicatorPage,
  IBasicIndicator,
  IIncludedId
} from '../utils/interfaces'
import PopupModal from '../helperComponents/PopUpModal'
import {
  AccessDenied,
  FunctionalButton,
  InitialLoadingScreen,
  EntityHeader
} from '../helperComponents/_components'
import {
  Icon3Dots // IconCollapse,
  // IconExpand
  // IconExpandMore
} from '../helperComponents/Icons'
import {
  generateFakeChartReference,
  generateTitlesForFidsInAString,
  isEmpty,
  transformFetchedRangedDetailsScenariotoRangeInputs
} from '../utils/functions'
import {
  exportToExcel,
  getAllValuesGroupedByKey
} from '../utils/transformingData'
import { MyTreeChart } from '../charts/GoogleCharts'
import CustomContextMenu from '../helperComponents/CustomContextMenu'
import UploadPage from '../helperComponents/UploadPage'

import RangeSlider2 from '../helperComponents/RangeSlider2'
import CreateTrendline from '../trendlines/CreateTrendline'
import { HighChartsLinearChart } from '../charts/HighChartsReact'
import RenameMajorEntity from '../helperComponents/RenameMajorEntity'
import { ChartLine } from '../charts/interfaces'
import DataEntityParameters from '../helperComponents/DataEntityParameters'
// import DataTableParameters from '../helperComponents/DataTableParameters'
// import DataTableValues from '../helperComponents/DataTableValues'

const CalculatedIndicator = ({
  indicatorId,
  userTags,
  favourites,
  readonly,
  inner,
  scenarios
}: ICalculatedIndicatorPage) => {
  try {
    // const [selectedDataMappedMode, setSelectedDataMappedMode] = useState<
    //   'value' | 'arrow' | 'off'
    // >(window.globalSettings.rises_and_drops_in_sd.state)

    const fetchedIndicatorParameters = useRef<ICalculatedIndicator>(
      {} as ICalculatedIndicator
    )
    const [chartLines, setChartLines] = useState<ChartLine[]>([])

    // const [enabledKeys, setEnabledKeys] = useState<string[]>([])
    const [readOnly, setReadOnly] = useState<boolean>(readonly)
    // eslint-disable-next-line
    const allFetchedIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])
    const [modalIndicatorEditData, setModalIndicatorEditData] =
      React.useState(false)
    const [modalCreateTrendline, setModalCreateTrendline] =
      useState<boolean>(false)

    const files = useRef<File[] | null>(null)
    const [accessAllowed, setAccessAllowed] = React.useState(false)
    const [fetchedIndicatorData, setFetchedIndicatorData] = React.useState(
      {} as any
    )

    const fetchedDataTransformed = useRef<IDataMapped>({} as IDataMapped)
    // eslint-disable-next-line
    const fetchedAllIndicators = useRef<
      (IBasicIndicator | ICalculatedIndicator | ITrendline)[]
    >([])

    const [fullDates, setFullDates] = React.useState<string[]>([])
    // const [dataMappedCellWidth, setDataMappedCellWidth] = useState<number>(50)
    const [pageLoading, setPageLoading] = React.useState(false)
    const [modalDeleteIndicator, setModalDeleteIndicator] =
      React.useState(false)
    const defaultContextMenuSettings = {
      top: 0,
      left: 0,
      show: false
    }

    const [contextMenuDetails, setContextMenuDetails] = useState(
      defaultContextMenuSettings
    )
    const [
      modalIndicatorNameAndDescription,
      setModalIndicatorNameAndDescription
    ] = useState(false)

    const [selectedDataBoundaries, setSelectedDataBoundaries] = useState<
      string[]
    >([])

    const [modalEntityRange, setModalEntityRange] = useState<boolean>(false)
    const [includedIds, setIncludedIds] = useState<IIncludedId[]>([])
    const [indicatorDataMode, setIndicatorDataMode] = useState<string>('values')

    const [ownerInfo, setOwnerInfo] = useState({
      profileImage: '',
      username: ''
    })

    // const [baseCompositeIndicatorId, setBaseCompositeIndicatorId] =
    //   useState<string>('')
    // const [delayedStartDate, setDelayedStartDate] = useState<string>('')
    // const [delayedInitialDate, setDelayedInitialDate] = useState<string>('')

    // const [offsetStarting, setOffsetStarting] = useState<string>('1')
    // const [selectedIndicator, setSelectedIndicator] = useState<string>('')

    // const [modalChartExpanded, setModalChartExpanded] = useState<boolean>(true)
    const [showRelationalTree, setShowRelationalTree] = useState<boolean>(false)
    // const [fetchedCollections, setFetchedCollections] = useState<ICollection[]>(
    //   []
    // )

    // const [changesToInputs, setChangesToInputs] = useState<boolean>(false)

    const [isCurrentIndicatorFavourite, setIsCurrentIndicatorFavourite] =
      useState(false)
    const resetContextMenuDetails = () => {
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
      }
    }

    // const checkChanges = () => {
    //   const initialMode =
    //     fetchedIndicatorParameters.current?.computation_mode &&
    //     fetchedIndicatorParameters.current?.computation_mode.includes('---')
    //       ? fetchedIndicatorParameters.current?.computation_mode.split('---')
    //       : fetchedIndicatorParameters.current?.computation_mode

    //   if (!initialMode) {
    //     return false
    //   }

    //   return (
    //     (offsetStarting !== initialMode[1] &&
    //       indicatorComputationMode === 'offset') ||
    //     (delayedInitialDate !== initialMode[1] &&
    //       indicatorComputationMode === 'delayed') ||
    //     (delayedInitialDate !== initialMode[1] &&
    //       indicatorComputationMode === 'delayedTotal') ||
    //     (delayedStartDate !== initialMode[2] &&
    //       indicatorComputationMode === 'delayed') ||
    //     (delayedStartDate !== initialMode[2] &&
    //       indicatorComputationMode === 'delayedTotal') ||
    //     (baseCompositeIndicatorId !== initialMode[1] &&
    //       indicatorComputationMode === 'composite')
    //   )
    // }

    const resetModals = () => {
      window.collapseSidePanel(true)
      setModalDeleteIndicator(false)
      setModalIndicatorNameAndDescription(false)
      setModalCreateTrendline(false)
      // setModalChartExpanded(false);

      setContextMenuDetails(defaultContextMenuSettings)
      setModalIndicatorEditData(false)
      setShowRelationalTree(false)
      setModalEntityRange(false)
    }

    const handleContextMenuDetails = (e: any) => {
      e.preventDefault()
      if (contextMenuDetails.show) {
        setContextMenuDetails(defaultContextMenuSettings)
        return
      }
      resetModals()
      setContextMenuDetails({
        show: true,
        top: e.pageY,
        left: e.pageX + 10
      })
    }

    const checkIfIndicatorIsFavourite = () => {
      if (favourites === undefined) {
        return false
      }

      const isFavourite =
        favourites.calculated_indicators.length > 0 &&
        favourites.calculated_indicators.find(
          (item: ICollectionEntry) => item.id === indicatorId
        ) !== undefined

      return isFavourite
    }

    // const filterOutIndicatorKey = (key: IEntityAllKeys) =>
    //   key.title !== indicatorId

    // const filterOutIndicatorKeyAndTrendlines = (key: IEntityAllKeys) =>
    //   key.title !== indicatorId && key.base_indicator !== indicatorId

    // const handleUpdateComputationalMode = async () => {
    //   const newMode = indicatorComputationMode.includes('offset')
    //     ? indicatorComputationMode + '---' + offsetStarting
    //     : indicatorComputationMode.includes('delayed') ||
    //         indicatorComputationMode.includes('delayedTotal')
    //       ? indicatorComputationMode +
    //         '---' +
    //         delayedInitialDate +
    //         '---' +
    //         delayedStartDate
    //       : indicatorComputationMode + '---' + baseCompositeIndicatorId

    //   const res = await updateIndicatorCalculatedComputationalMode({
    //     indicatorId: modalEditConfiguration.fid,
    //     newMode,
    //     dataMode: indicatorDataMode
    //   })

    //   return res
    // }

    // const handleOpenConfigurationModal = (fid: string) => {
    //   setModalEditConfiguration({ open: true, fid })
    // }

    const fetchIndicator = async (indicatorId: string, noClose?: boolean) => {
      if (pageLoading) return

      try {
        if (!noClose) {
          resetModals()
          setPageLoading(true)
        }

        const response = await fetchChosenIndicator(indicatorId)
        if (!response || response.fetchedIndicatorParameters.length === 0) {
          setPageLoading(false)
          setAccessAllowed(false)
          return
        }

        const fetchedParameters: ICalculatedIndicator =
          response.fetchedIndicatorParameters[0]
        const { trendlines } = response

        let allParams = [...response.fetchedIndicatorParameters, ...trendlines]

        // Remove duplicates, prioritizing the later occurrences
        allParams = allParams
          .reverse()
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.fid === item.fid)
          )
          .reverse()

        // allParams = allParams.map(
        //   (ind: ICalculatedIndicator | ITrendline | IBasicIndicator) => {
        //     if (ind.type === 'calculated') {
        //       return {
        //         ...ind,
        //         onclick: handleOpenConfigurationModal
        //       }
        //     }
        //     return ind
        //   }
        // )

        const combinedData = response.fetchedIndicatorData

        const transformed = getAllValuesGroupedByKey(combinedData)
        const transformedRange =
          transformFetchedRangedDetailsScenariotoRangeInputs(
            fetchedParameters.range_chosen
          )
        const isFavourite = checkIfIndicatorIsFavourite()
        const idsIncluded =
          fetchedParameters.ids_included &&
          fetchedParameters.ids_included.length > 0
            ? fetchedParameters.ids_included
            : [
                {
                  fid: indicatorId,
                  chart: true,
                  type: 'calculated',
                  data: true
                }
              ]

        try {
          const refs = generateFakeChartReference(
            fetchedParameters,
            response.allIndicators
          )
          fetchedParameters.chart_references = [refs]
        } catch (err) {}

        setReadOnly(!fetchedParameters.is_own)
        setOwnerInfo({
          profileImage: response.owner.profile_image,
          username: response.owner.username
        })

        setChartLines(response.chartLines)
        setFetchedIndicatorData(combinedData)
        setIndicatorDataMode(fetchedParameters.data_mode)
        fetchedIndicatorParameters.current = fetchedParameters
        allFetchedIndicators.current = allParams
        setFullDates(response.fullDates)
        fetchedAllIndicators.current = response.allIndicators.map(
          (item: any) => {
            if (item.type !== 'calculated') return item
            return allParams.find((ind: any) => ind.fid === item.fid) || item
          }
        )

        fetchedDataTransformed.current = transformed
        setSelectedDataBoundaries(transformedRange)
        setIsCurrentIndicatorFavourite(isFavourite)
        setIncludedIds(idsIncluded)
        setAccessAllowed(true)
        setPageLoading(false)
      } catch (err: any) {
        console.error("Couldn't fetch indicator", err)
        setPageLoading(false)
      }
    }

    const refreshIndicator = async () => {
      if (pageLoading) return
      await fetchIndicator(indicatorId)
    }

    const handleRename = async ({
      newName,
      newDescription,
      newShortDescription
    }: any) => {
      const res = await updateIndicarorDetails({
        indicatorId,
        newName,
        newDescription,
        newShortDescription
      })

      if (res) {
        setModalIndicatorNameAndDescription(false)
        refreshIndicator()
      }

      return res
    }

    // const addIndicatorToShown = async (fid: string) => {
    //   const res = await updateSelectedColumnsEntity({
    //     selectedChartList: [
    //       ...entityChartKeys.map((item) => item.title),
    //       fid
    //     ].join(', '),
    //     selectedDataList: [
    //       ...entityChartKeys.map((item) => item.title),
    //       fid
    //     ].join(', '),
    //     entityId: indicatorId,
    //     entityType: 'calculated'
    //   })

    //   if (res) {
    //     return fid
    //   }
    // }

    const refreshIndicatorNoClose = async (contextMenu?: boolean) => {
      if (pageLoading) return

      await fetchIndicator(indicatorId, true).then(() => {
        if (contextMenu) {
          resetContextMenuDetails()
        }
      })
    }

    const applyRangeChange = async (lowerValue: string, upperValue: string) => {
      const result = await updateRangeSelected({
        rangeSelected: `[${lowerValue},${upperValue})`,
        entityId: indicatorId,
        entityType: 'calculated_indicator'
      })
      if (result) {
        refreshIndicatorNoClose()
      }
      return result
    }

    // const analyseWithAI = async () => {
    //   const exportName = `${fetchedIndicatorParameters.current.title}.pdf`

    //   const combineDataForExport = () => {
    //     if (!fetchedDataTransformed || !fetchedIndicatorParameters) return null

    //     const description =
    //       'File description: ' + fetchedIndicatorParameters.current?.meaning
    //     const data = fetchedDataTransformed.current?.data.map((item) => {
    //       const titles = fetchedAllIndicators.current.find(
    //         (indicator) => indicator.fid === item.title
    //       )
    //       const title = titles ? titles.title : item.title

    //       return {
    //         title,
    //         data: item.values
    //       }
    //     })

    //     return { description, data }
    //   }

    //   const combinedData = combineDataForExport()

    //   if (combinedData) {
    //     const doc = new jsPDF() // eslint-disable-line
    //     doc.setFontSize(12)

    //     doc.text(combinedData.description, 10, 10)

    //     let y = 20
    //     combinedData.data.forEach((item) => {
    //       doc.text(item.title + ': ' + JSON.stringify(item.data), 10, y)
    //       y += 10
    //     })

    //     const blob = doc.output('blob')

    //     const file = new File([blob], exportName, {
    //       type: 'application/pdf'
    //     })
    //     files.current = [file]
    //     setDetailsTabMode('ai')
    //     return true
    //   }
    //   return false
    // }

    // const handleSwitchPrimaryIndicator = async (fid: string) => {
    //   // find indicator in allIndicatorsFetched
    //   const indicator = fetchedAllIndicators.current.find(
    //     (ind) => ind.fid === fid
    //   )

    //   if (!indicator || indicator.type !== 'calculated') {
    //     return false
    //   }

    //   const update = await togglePrimarySettingCalculatedIndicator({
    //     base: indicatorId,
    //     fid
    //   })

    //   if (!update) {
    //     return false
    //   }

    //   refreshIndicator()

    //   return true
    // }

    // const applyMappedCellWidthChange = (e: any) => {
    //   if (
    //     e.target.value >= 20 &&
    //     e.target.value <= 150 &&
    //     e.target.value.toString().slice(0, 1) !== '0' &&
    //     !isNaN(e.target.value)
    //   ) {
    //     const value = e.target.value
    //     setDataMappedCellWidth(value)
    //     const tickWidth = 17 * (value / 50)

    //     // Assuming 'value' and 'tickWidth' are defined and have numeric types
    //     // 1. Set width for elements with classes .date-tick and .tick
    //     const ticks: any = document.querySelectorAll('.date-tick, .tick')
    //     ticks.forEach(
    //       (tick: { style: { width: string } }) =>
    //         (tick.style.width = `${value}px`)
    //     )

    //     // 2. Set min-width for elements with classes .date-tick and .tick
    //     ticks.forEach(
    //       (tick: { style: { minWidth: string } }) =>
    //         (tick.style.minWidth = `${value}px`)
    //     )

    //     // 3. Set padding-left to 0 for elements with the selector .ticks-entry .ticks span
    //     const spanElements: any = document.querySelectorAll(
    //       '.ticks-entry .ticks span'
    //     )
    //     spanElements.forEach(
    //       (span: { style: { paddingLeft: string } }) =>
    //         (span.style.paddingLeft = '0')
    //     )

    //     // 4. Set width for images inside elements with the class .tick
    //     const tickImages: any = document.querySelectorAll('.tick img')
    //     tickImages.forEach(
    //       (img: any) =>
    //         (img.style.width = `${tickWidth > 17 ? 17 : tickWidth}px`)
    //     )
    //   }
    // }

    const handleCreateForecast = async () => {
      const res = await createNewForecast(indicatorId)

      if (!res) {
        return false
      }

      // res = await addIndicatorToShown(res)

      window.switchFunctions.forecast(res)
      return true
    }

    const handleExportData = () => {
      try {
        if (
          !fetchedDataTransformed ||
          !fetchedDataTransformed.current.data ||
          !fetchedDataTransformed.current.data[0]
        ) {
          return false
        }
        const title =
          fetchedIndicatorParameters.current?.title || 'Default Title'

        const dates = fetchedDataTransformed.current.data[0].dates
        const formattedDates = dates.map((date: string) => {
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        })
        const values = fetchedDataTransformed.current.data[0].values
        const data = values.map((item: any, index: number) => {
          return {
            date: formattedDates[index],
            title: item
          }
        })

        exportToExcel(
          data,
          `${title} - ${new Date()
            .toLocaleDateString()
            .split('/')
            .join('-')
            .slice(0, 10)}`
        )

        return true
      } catch (err: any) {
        console.error(err)
      }
      return false
    }

    // const updateFrequencyAndEquation = async (
    //   frequency: Frequency,
    //   equation: IEquationPiece[]
    // ) => {
    //   if (!frequency || !equation) {
    //     return false
    //   }

    //   const [res1, res2] = await Promise.all([
    //     updateEquationCalculatedIndicator({
    //       equation,
    //       indicatorId
    //     }),
    //     updateIndicatorFieldValue({
    //       fid: indicatorId,
    //       field: 'frequency',
    //       newValue: frequency
    //     })
    //   ])

    //   if (!res1 || !res2) {
    //     return false
    //   }

    //   return true
    // }

    const load = async () => {
      if (pageLoading) return

      await fetchIndicator(indicatorId)
    }

    useEffect(() => {
      load()
    }, [indicatorId])

    // useEffect(() => {
    //   setChangesToInputs(checkChanges())
    // }, [
    //   offsetStarting,
    //   selectedIndicator,
    //   delayedInitialDate,
    //   delayedStartDate,
    //   baseCompositeIndicatorId
    // ])

    if (pageLoading) {
      return <InitialLoadingScreen />
    } else if (!accessAllowed && !pageLoading) return <AccessDenied />
    else if (accessAllowed && !pageLoading && fetchedIndicatorParameters) {
      return (
        <div className="entity calculated">
          <EntityHeader
            subtitle="Calculated Indicator"
            ownerInfo={ownerInfo}
            title={fetchedIndicatorParameters.current?.title}
            description={fetchedIndicatorParameters.current?.short_description}
            titleClick={() => window.switchFunctions.indicator(indicatorId)}
          />
          <div className="entity-blocks">
            <div className="container w-12">
              <div className="entity-parameters justify-content-start">
                {!readOnly && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    functionToExecute={() => {
                      setModalCreateTrendline(true)
                    }}
                    noReturn
                    doesReset
                    initialButtonState={'Generate Trendline'}
                    iconPlusMode
                    collapsible
                  />
                )}
                {!readOnly && (
                  <FunctionalButton
                    className="inline no-btn default-text no-hover no-border"
                    functionToExecute={handleCreateForecast}
                    doesReset
                    initialButtonState={'Generate Forecast'}
                    iconPlusMode
                    collapsible
                  />
                )}
                <button
                  className="inline no-btn default-text no-hover no-border"
                  onClick={handleContextMenuDetails}
                >
                  <Icon3Dots />
                </button>
              </div>
            </div>
            <div className="container w-6 taller scroll no-scrollbar">
              <div className="entity-chart">
                {readOnly && (
                  <span className="banner-strip small warning col-12">
                    Read Only
                  </span>
                )}

                <HighChartsLinearChart
                  pageDetails={{
                    type: 'calculated',
                    title: fetchedIndicatorParameters.current.title,
                    fid: indicatorId,
                    refresh: () => refreshIndicatorNoClose()
                  }}
                  readOnly={readOnly}
                  allIndicators={fetchedAllIndicators.current}
                  chartOptions={{
                    xAxisTitle: 'Month',
                    yAxisTitle: 'Value',
                    title: fetchedIndicatorParameters.current?.title,
                    mediumSize: true
                  }}
                  page="calculated"
                  chartData={{
                    fetchedData: fetchedIndicatorData,
                    chartLines,
                    fullDates,
                    titles: includedIds.map((item: IIncludedId) => {
                      const matchedIndicator =
                        fetchedAllIndicators.current.find(
                          (indicator: any) => indicator.fid === item.fid
                        )

                      return {
                        [item.fid]: matchedIndicator?.title || ''
                      }
                    }),
                    filteredKeys: [...includedIds]
                      .filter((_) => _.chart)
                      .map((item) => item.fid),
                    deviations: allFetchedIndicators.current as ITrendline[]
                  }}
                  componentOptions={{
                    own:
                      !readOnly &&
                      fetchedIndicatorParameters &&
                      !fetchedIndicatorParameters.current?.admin_access,
                    parametersFetched: fetchedIndicatorParameters.current
                    // onlyDeviations: indicatorDataMode === 'deviations'
                  }}
                />
              </div>
            </div>
            <div className="container w-6 taller no-scrollbar">
              <div className="entity-info" onClick={resetContextMenuDetails}>
                <DataEntityParameters
                  page="calculated"
                  readOnly={readOnly}
                  refreshFunction={refreshIndicator}
                  fid={indicatorId}
                  fetchedIndicatorParameters={
                    fetchedIndicatorParameters.current
                  }
                  fetchedDataTransformed={fetchedDataTransformed.current}
                  userTags={userTags}
                  refreshEntityNoClose={refreshIndicatorNoClose}
                  handleExportData={handleExportData}
                  files={files.current}
                  aiInstructions="Review this document elucidating the calculated indicator derived from other indicators or values, and furnish a thorough analysis of its contents. Assess the significance of each constituent indicator or value in the calculation process, and conduct a comparative evaluation among them. Illuminate any noteworthy features inherent in the calculated indicator."
                />
              </div>
            </div>
            {/* <div className="container w-12 fit">
              <div className="entity-parameters">
                {!readOnly && (
                  <div className="flex gap-2 middle">
                    <FunctionalButton
                      className="inline no-btn default-text no-hover no-border"
                      noReturn
                      functionToExecute={() => setModalCreateNewVariation(true)}
                      doesReset
                      initialButtonState={'Create a variation'}
                      iconPlusMode
                      combinedButtonTitle
                    />
                    {modalCreateNewVariation && (
                      <Fragment>
                        <input
                          type="text"
                          value={newVariationName}
                          onChange={(e) => setNewVariationName(e.target.value)}
                          placeholder="Variation Name"
                        />
                        <FunctionalButton
                          className="primary"
                          functionToExecute={handleCreateNewVariation}
                          initialButtonState="Submit"
                          iconSaveMode
                          doesReset
                          disabled={newVariationName.length === 0}
                        />
                        <FunctionalButton
                          className="destructive"
                          functionToExecute={() =>
                            setModalCreateNewVariation(false)
                          }
                          initialButtonState="Undo"
                          iconBackwardsMode
                          doesReset
                        />
                      </Fragment>
                    )}
                  </div>
                )}
              </div>
            </div> */}
          </div>
          <CustomContextMenu
            contextMenuSettings={contextMenuDetails}
            menu={[
              {
                onClick: () => {
                  resetModals()
                  setModalIndicatorNameAndDescription(true)
                },
                title: 'Edit Details',
                noAccess: readOnly
              },
              {
                onClick: () => {
                  resetModals()
                  window.switchFunctions.upload()
                },
                title: 'Create/Clone Indicator'
              },
              {
                onClick: async () => {
                  resetModals()
                  await updateIndicatorDataMode({
                    entityFid: indicatorId,
                    entityType: 'calculated',
                    newMode:
                      indicatorDataMode === 'values' ? 'deviations' : 'values'
                  })
                  refreshIndicator()
                },
                title:
                  indicatorDataMode === 'values'
                    ? 'Deviations Data Mode'
                    : 'Values Data Mode'
              },
              {
                onClick: () => {
                  resetModals()
                  setShowRelationalTree(true)
                },
                title: 'Show Relational Tree'
              },
              {
                onClick: () =>
                  modifyCollectionEntries({
                    entityType: 'calculated',
                    entityId: indicatorId,
                    action: isCurrentIndicatorFavourite ? 'remove' : 'add',
                    entityName: fetchedIndicatorParameters.current?.title,
                    collectionId: favourites === undefined ? 0 : favourites.id
                  }),
                title: isCurrentIndicatorFavourite
                  ? 'Remove from My Library'
                  : 'Add to My Library',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                },
                noAccess: fetchedIndicatorParameters.current?.is_own
              },
              {
                onClick: async () =>
                  await updateEntityInverse({
                    fid: fetchedIndicatorParameters.current?.fid || '',
                    type: 'calculated'
                  }),
                title: fetchedIndicatorParameters.current?.inverse
                  ? 'Toggle Not Inverse'
                  : 'Toggle Inverse',
                functionalButtonSettings: {
                  exists: true,
                  refreshFunction: () => refreshIndicatorNoClose(true),
                  doesReset: true
                }
              },
              {
                onClick: () => {
                  resetModals()
                  setModalEntityRange(true)
                },
                noAccess: readOnly,
                title: 'Edit Range'
              },
              {
                onClick: () => {
                  resetModals()
                  setModalDeleteIndicator(true)
                },
                title: 'Delete',
                noAccess: readOnly
              }
            ]}
          />

          {modalCreateTrendline && (
            <PopupModal
              isOpen={modalCreateTrendline}
              onClose={() => setModalCreateTrendline(false)}
              title="Create Trendline"
              size="largest"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
              noChanges
              className="p-0"
            >
              <CreateTrendline
                fetchedDataTransformed={
                  fetchedDataTransformed.current.data.filter(
                    (entry) => entry.title === indicatorId
                  )[0]
                }
                fetchedIndicatorParameters={fetchedIndicatorParameters.current}
                indicatorId={indicatorId}
                fullDates={fullDates}
                source="calculated"
                source_fid={indicatorId}
                closeModal={resetModals}
              />
            </PopupModal>
          )}

          {modalIndicatorEditData && !readOnly && (
            <PopupModal
              isOpen={modalIndicatorEditData && !readOnly}
              onClose={() => setModalIndicatorEditData(false)}
              title="Edit Indicator Details"
              size={'large'}
              handleSubmit={() => undefined}
              saveButtonExists={false}
              noChanges={true}
            >
              <UploadPage
                fullFetchedIndicatorsParameters={allFetchedIndicators.current}
                refreshFunction={refreshIndicator}
                singleIndicatorMode={{
                  exists: true,
                  indicatorId
                }}
                mode="upload"
              />
            </PopupModal>
          )}
          {modalEntityRange && (
            <PopupModal
              isOpen={modalEntityRange && !readOnly}
              onClose={() => setModalEntityRange(false)}
              title="Calculated Range"
              size="medium"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              customMessage="If you leave this page, the changes will not be saved."
              noChanges={true}
            >
              <RangeSlider2
                dataArray={fullDates}
                initialDetails={transformFetchedRangedDetailsScenariotoRangeInputs(
                  fetchedIndicatorParameters.current?.range_complete || ''
                )}
                selectedDetails={selectedDataBoundaries}
                dateSliderMode={true}
                iconMode={'save'}
                customStyles={{
                  width: 400,
                  padding: '6px 20px 6px 5px'
                }}
                onApply={applyRangeChange}
              />
            </PopupModal>
          )}
          {modalDeleteIndicator && !readOnly && (
            <PopupModal
              isOpen={modalDeleteIndicator && !readOnly}
              onClose={() => setModalDeleteIndicator(false)}
              title="Are you sure you want to delete this indicator?"
              size="small"
              handleSubmit={() => undefined}
              saveButtonExists={false}
              inner
            >
              <div className="flex-row center middle margin-1">
                <FunctionalButton
                  className="destructive"
                  functionToExecute={async () =>
                    await deleteIndicatorByName(indicatorId)
                  }
                  initialButtonState={'Delete'}
                  refreshOnComplete={{
                    exists: true,
                    refreshFunction: () =>
                      window.switchFunctions.deleted(indicatorId)
                  }}
                />
              </div>
            </PopupModal>
          )}

          {modalIndicatorNameAndDescription && (
            <PopupModal
              isOpen={modalIndicatorNameAndDescription && !readOnly}
              onClose={resetModals}
              title="Edit Indicator Details"
              size="large"
              handleSubmit={() => undefined}
              inner
            >
              <RenameMajorEntity
                functionToExecute={handleRename}
                title={fetchedIndicatorParameters.current.title}
                description={fetchedIndicatorParameters.current.meaning}
                short_description={
                  fetchedIndicatorParameters.current.short_description
                }
              />
            </PopupModal>
          )}
          {showRelationalTree && (
            <PopupModal
              isOpen={showRelationalTree}
              onClose={() => setShowRelationalTree(false)}
              title="Relational Tree"
              size="large"
              handleSubmit={() => undefined}
              noChanges={true}
              inner
            >
              {!isEmpty(
                fetchedIndicatorParameters.current?.relational_tree
              ) && (
                <MyTreeChart
                  jsonData={
                    fetchedIndicatorParameters.current?.relational_tree || ''
                  }
                  dataOptions={{
                    titles: generateTitlesForFidsInAString(
                      JSON.stringify(
                        fetchedIndicatorParameters.current?.relational_tree
                      ),
                      fetchedAllIndicators.current
                    )
                  }}
                />
              )}
            </PopupModal>
          )}
        </div>
      )
    } else return <AccessDenied />
  } catch (error) {
    console.error('Error rendering indicator page:', error)
    return <AccessDenied />
  }
}

export default CalculatedIndicator
